/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

import { terms, privacypolicy, aboutus } from "../Routes/Routes";
import { apiurl } from "../maincomponent/Url";
import { get } from "../services/services";

function Footer2() {
  const [subscribe, setSubscribe] = useState("");
  const [footerdata, Setfooterdata] = useState([]);
  const [footersubsdata, Setfootersubsdata] = useState([]);
  const [errors, setErrors] = useState("");
  const handleChange = e => {
    const { value } = e.target;
    setSubscribe(value);
    setErrors(value);
  };
  const subscibed = e => {
    e.preventDefault();
    const newErrors = {};
    if (!subscribe) {
      newErrors.subscribe = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(subscribe)) {
      newErrors.subscribe = "Please enter a valid email address.";
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        axios
          .post(`${apiurl}visitor/getSubscribe`, {
            email: subscribe,
          })
          .then(response => {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setSubscribe("");
            } else {
              toast.success("Email subscribed successfully.");
              setSubscribe("");
            }
          })
          .catch(error => {
            console.error(error);
          });
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    }
  };
  useEffect(() => {
    get(`visitor/getFooter`)
      .then(response => {
        Setfooterdata(response?.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${apiurl}visitor/getSubscription`)
      .then(response => {
        
        Setfootersubsdata(response?.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <footer className="inner-footer">
        <div className="container">
          <div className="inner-footer-content">
            <div className="secure-box">
              <ul className="flex align-center">
                <li>
                  <img src="src/images/secure-img.png" alt="secure" />
                </li>
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: footerdata?.secureText,
                    }}
                  ></p>
                </li>
              </ul>
            </div>
            <div className="content-row flex">
              <div className="col-left-box">
                <a href="/">
                  <img src="src/images/site-logo.svg" alt="" />
                </a>
                <p
                  dangerouslySetInnerHTML={{
                    __html: footerdata?.detail,
                  }}
                >
                  {/* Making the world a better place through constructing elegant
                  hierarchies. */}
                  {/* {footerdata?.detail} */}
                </p>
                <div className="social-icon">
                  <ul className="flex">
                    <li className="flex align-center">
                      <a
                        href={footerdata?.site}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src="src/images/globe.svg" alt="socialicon" />
                      </a>
                      <a
                        href={footerdata?.facebook}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src="src/images/Facebook.svg" alt="socialicon" />
                      </a>
                      <a
                        href={footerdata?.twitter}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src="src/images/Twitter.svg" alt="socialicon" />
                      </a>
                      <a
                        href={footerdata?.instagram}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src="src/images/inst-new.svg" alt="socialicon" />
                      </a>
                    
                      <a
                        href={footerdata?.linkedin}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src="src/images/Linkedin1.png" alt="socialicon" />
                      </a>
                      <a
                        href={footerdata?.youtube}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src="src/images/Youtube.svg" alt="socialicon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-right-box flex">
                <div className="box-wrapper flex">
                  <div className="left-box">
                    <div className="heading-box">
                      <h5>Company</h5>
                      <ul>
                        <li>
                          <Link to={aboutus}>About</Link>
                        </li>
                        <li>
                          <Link to="/support">Support</Link>
                        </li>
                        <li>
                          <a href={terms}>Terms & Conditions</a>
                        </li>
                        <li>
                          <Link to={privacypolicy}>Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="right-box">
                    <div className="news-letter">
                      <ul>
                        <li>
                          <h5
                            dangerouslySetInnerHTML={{
                              __html: footersubsdata?.title,
                            }}
                          ></h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: footersubsdata?.message,
                            }}
                          ></p>
                        </li>
                        <li>
                          <form action="">
                            <div className="form-group flex">
                              <input
                                type="email"
                                name="subscribe"
                                placeholder="Enter your email"
                                className="form-control"
                                value={subscribe}
                                onChange={handleChange}
                              />
                              <button onClick={subscibed} className="cta-btn">
                                Subscribe
                              </button>
                            </div>
                            {errors.subscribe && (
                              <p className="subscribe-error">
                                {errors.subscribe}
                              </p>
                            )}
                          </form>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="copy-right">
              <div className="flex align-center">
                <p
                  dangerouslySetInnerHTML={{
                    __html: footerdata?.copyRightText,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer2;
