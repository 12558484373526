import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer3 from "../../common/Footer3";

function Signup() {
	const [activeAccount, setActiveAccount] = useState("");
	const [error, setError] = useState("");
	const errorMsg =()=>{
		if (!activeAccount) {
			setError("Please select an account type.");
		  }
	};
	const clearError = () => {
		setError("");
	  };
	const handleContinue = () => {
		let destination = '';
		if (activeAccount === 'investor') {
		  destination = '/investor-signup?accountType=Investor';
		} else if (activeAccount === 'startup') {
		  destination = '/startup-Signup?accountType=StartUp';
		} 
		return destination;
	  };
	  
  return (
    <>
	      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Signup</title>
      
      </Helmet>
     <section className="signUp-Sec">
		<div className="content-row flex">
			<div className="left-sidebar h-100 signupleftbox">
				<div className="logo-box">
					<Link to="/">
						<img src="src/images/site-logo.svg" alt=""/>
					</Link>
				</div>
				<div className="bottom-line"></div>
				<div className="bottom-line linetwo"></div>
				<div className="bottom-line linethreee"></div>
				<div className="bottom-line linefourth"></div>
				<div className="bottom-line linefive"></div>
				<div className="bottom-line linesix"></div>
				<div className="bottom-line lineseven"></div>
			</div>
			<div className="right-box custom-bg">
				<div className="account-type">
				<div className="logo-box mobileview">
					<Link to="/">
						<img src="src/images/site-logo.svg" alt=""/>
					</Link>
				</div>
					<div className="top-comman-text-box">
						<h5>Select Account Type</h5>
						<p>If you need more info, please check out <a href="/support">FAQ Page</a></p>
					</div>
					<div className={`box flex ${activeAccount === 'investor' ? 'active' : ''}`} onClick={() => {setActiveAccount('investor'); clearError();}}>
						<div className="img-box">
						{activeAccount === 'investor' ? 
						<img src="src/images/user-color.svg" alt=""/>:
							<img src="src/images/User.svg" alt=""/>
        }		
						</div>
						<div className="text-box">
							<h6>Investor Account</h6>
							<p>Invest in innovative startups and grow your portfolio.</p>
						</div>
					</div>
					<div className={`box flex ${activeAccount === 'startup' ? 'active' : ''}`} onClick={() => {setActiveAccount('startup'); clearError();}}>
						<div className="img-box">
						{activeAccount === 'startup'?
						<img src="src/images/user-colorful.svg" alt=""/>:
							<img src="src/images/Startup.svg" alt=""/>
						}
						</div>
						<div className="text-box custom-signup">
							<h6>Startup Account</h6>
							<p>Create corporate account to many users</p>
						</div>
					</div>
					{error && <p className="error-msg">{error}</p>}
					<div className="btn-box flex space-between">
						<Link to="/" className="cta-btn back-btn">Back</Link>
						<Link to={handleContinue()}  onClick={errorMsg}  className="cta-btn blue-btn">Continue</Link>
					</div>
				</div>
				<div className="bottom-link">
					<ul className="flex">
						<li><a href="/terms&conditions">Terms</a></li>
						<li><a href="/privacy-policy">Privacy Policy</a></li>
						<li><a href="/contact-us">Contact Us</a></li>
					</ul>
				</div>
			</div>
		</div>
	</section>
	<Footer3/>
    </>
  );
}
export default Signup;
