/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { Industries } from "../../common/localStates";
import { post, get } from "../../services/services";
import  { setItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";

function SignupStep4() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    websiteURL: "",
    yearEstablished: "",
    industrySector: "",
    stageOfBusiness: "",
    businessDescription: "",
    fundingNeeds: "",
    intellectualProperty: "",
    trademarkText: "",
  });
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === "yearEstablished" && value.length > 4) {
      return;
    }
    if (name === "businessDescription" && value.length > 1500) {
      setErrors(previousError => ({
        ...previousError,
        businessDescription: "Word limit is over of 1500 words",
      }));
    }
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (formData.websiteURL) {
      if (!/^https?:\/\/.*/i.test(formData.websiteURL)) {
        newErrors.websiteURL =
          "Website URL must must start with https:// or https:// and and include .com, .in, or .org somewhere in the URL.";
      }
    }
    if (!formData.yearEstablished) {
      newErrors.yearEstablished = "Year Established is required.";
    }
    if (!formData.industrySector) {
      newErrors.industrySector = "Industry Sector is required.";
    }
    if (!formData.stageOfBusiness) {
      newErrors.stageOfBusiness = "Stage of Business is required.";
    }
    if (!formData.businessDescription) {
      newErrors.businessDescription = "Business Description is required.";
    } else if (formData.businessDescription.length > 1500) {
      newErrors.businessDescription =
        "Business Description is exceding 1500 words";
    }
    if (!formData.fundingNeeds) {
      newErrors.fundingNeeds = "Funding Amount is required.";
    }
    if (!formData.intellectualProperty) {
      newErrors.intellectualProperty = "Intellectual Property is required.";
    }
    if (!formData.trademarkText && formData.intellectualProperty === "Yes") {
      newErrors.trademarkText = "Intellectual Property text is required.";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const data = {
        website: formData.websiteURL != null ? formData.websiteURL : "",
        year_of_establish: formData.yearEstablished,
        industry_sector: formData.industrySector,
        stage_of_business: formData.stageOfBusiness,
        business_description: formData.businessDescription,
        funding_need: formData.fundingNeeds,
        intellectual_property: formData.intellectualProperty,
        tradeMark:
          formData.intellectualProperty === "Yes" ? formData.trademarkText : "",
      };

      post(`${apiurl}startups/createStartupone/${Userid}`, data)
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setLoading(false);
            } else {
              setItem("url", "/additional-questions");
              navigate("/additional-questions");
            }
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}startups/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          if (response?.data?.startups != null) {
            const startupData = response?.data?.startups;
            // const date = new Date(startupData.year_of_establish);
            // const formattedDate = date.toISOString().split("T")[0];
            setFormData({
              ...formData,
              websiteURL:
                startupData?.website != null ? startupData?.website : "",
              yearEstablished:
                startupData?.year_of_establish != null
                  ? startupData?.year_of_establish
                  : "",
              industrySector:
                startupData?.industry_sector != null
                  ? startupData?.industry_sector
                  : "",
              stageOfBusiness:
                startupData?.stage_of_business != null
                  ? startupData?.stage_of_business
                  : "",
              businessDescription:
                startupData?.business_description != null
                  ? startupData?.business_description
                  : "",
              fundingNeeds:
                startupData?.funding_need != null
                  ? startupData?.funding_need
                  : "",
              intellectualProperty:
                startupData?.intellectual_property != null
                  ? startupData?.intellectual_property
                  : "",
              trademarkText:
                startupData?.tradeMark != null ? startupData?.tradeMark : "",
            });
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Identity Business Verification 2</title>
      </Helmet>
      <section className="signUp-Sec">
        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex active">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4>Identity and Business Verification</h4>
                  <p>Verify Your Details</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4>Additional Questions</h4>
                  <p> Enhance Your Profile</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4>Unit Economics and Business Growth</h4>
                  <p>Showcase Your Potential</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4>Legal Compliance and Declarations</h4>
                  <p>Ensure Regulatory Compliance</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">5</div>
                <div className="text-box">
                  <h4>About Business</h4>
                  <p>Additional Info For Better Startup Information</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">6</div>
                <div className="text-box">
                  <h4>Completed</h4>
                  <p>Complete Your Registration Process</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">
                  Identity and Business Verification
                </h5>
                <form action="">
                  <div className="form-group">
                    <label htmlFor="">Website URL (if available)</label>
                    <input
                      type="text"
                      placeholder="https://"
                      className="form-control"
                      name="websiteURL"
                      value={formData.websiteURL}
                      onChange={handleChange}
                    />
                    {errors.websiteURL && (
                      <p className="error-msg">{errors.websiteURL}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Year Established<span className="required-sign">*</span>
                    </label>
                    <input
                      type="number"
                      name="yearEstablished"
                      placeholder="yyyy"
                      className="form-control"
                      value={formData.yearEstablished}
                      onChange={handleChange}
                      maxLength="4"
                    />
                    {errors.yearEstablished && (
                      <p className="error-msg">{errors.yearEstablished}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Industry Sector<span className="required-sign">*</span>
                    </label>
                    <select
                      name="industrySector"
                      className="form-control"
                      value={formData.industrySector}
                      onChange={handleChange}
                    >
                      <option value="--- Select ---">--- Select ---</option>
                      {Industries?.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                    {errors.industrySector && (
                      <p className="error-msg">{errors.industrySector}</p>
                    )}
                  </div>
                  <h5>Stage of Business</h5>
                  <div className="form-group custom-radio-btn">
                    <p>
                      What stage is your business currently in?
                      <span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Idea
                          <input
                            type="radio"
                            name="stageOfBusiness"
                            value="Idea"
                            checked={
                              formData.stageOfBusiness == "Idea" ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Pre-revenue
                          <input
                            type="radio"
                            name="stageOfBusiness"
                            value="Pre-revenue"
                            checked={
                              formData.stageOfBusiness == "Pre-revenue"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Revenue-generating
                          <input
                            type="radio"
                            name="stageOfBusiness"
                            value="Revenue-generating"
                            checked={
                              formData.stageOfBusiness == "Revenue-generating"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Scaling
                          <input
                            type="radio"
                            name="stageOfBusiness"
                            value="Scaling"
                            checked={
                              formData.stageOfBusiness == "Scaling"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                  {errors.stageOfBusiness && (
                    <p className="error-msg">{errors.stageOfBusiness}</p>
                  )}
                  <div className="form-group">
                    <label htmlFor="">
                      Business Description
                      <span className="required-sign">*</span>
                    </label>
                    <textarea
                      name="businessDescription"
                      className="form-control"
                      placeholder="Enter your text here (maximum 1500 characters)"
                      value={formData.businessDescription}
                      onChange={handleChange}
                      maxLength={1500}
                    ></textarea>
                    {errors.businessDescription && (
                      <p className="error-msg">{errors.businessDescription}</p>
                    )}
                    <div className="character-counter">
                      {formData.businessDescription ? (
                        <>
                          {" "}
                          {1500 - formData.businessDescription.length}{" "}
                          characters remaining
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <h5>
                    Funding Needs{" "}
                    <span className="funding-text">
                      (Mention amount and equity dilution)
                    </span>
                  </h5>
                  <div className="form-group">
                    <label htmlFor="">
                      What is the funding amount you are seeking?
                      <span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      name="fundingNeeds"
                      placeholder="exp: INR 50 lakhs for a 10% equity stake."
                      className="form-control"
                      value={formData.fundingNeeds}
                      onChange={handleChange}
                    />
                    {errors.fundingNeeds && (
                      <p className="error-msg">{errors.fundingNeeds}</p>
                    )}
                  </div>
                  <h5>Intellectual Property</h5>
                  <div className="form-group custom-radio-btn">
                    <p>
                      Do you have any patents or trademarks?
                      <span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Yes
                          <input
                            type="radio"
                            name="intellectualProperty"
                            value="Yes"
                            checked={
                              formData.intellectualProperty == "Yes"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          No
                          <input
                            type="radio"
                            name="intellectualProperty"
                            value="No"
                            checked={
                              formData.intellectualProperty == "No"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                  {errors.intellectualProperty && (
                    <p className="error-msg">{errors.intellectualProperty}</p>
                  )}

                  {formData.intellectualProperty === "Yes" && (
                    <div className="form-group">
                      <input
                        type="text"
                        name="trademarkText"
                        placeholder="Enter Trademarks"
                        className="form-control"
                        value={formData.trademarkText}
                        onChange={handleChange}
                      />
                      {errors.trademarkText && (
                        <p className="error-msg">{errors.trademarkText}</p>
                      )}
                    </div>
                  )}
                </form>
                <div className="btn-box flex space-between">
                  <Link
                    to="/identity-business-verfication1"
                    className="cta-btn back-btn"
                  >
                    Back
                  </Link>
                  <Link onClick={handleSubmit} className="cta-btn blue-btn">
                    {loading ? (
                      <div style={{ marginLeft: "-30%" }}>
                       <Loaderbutton/>{" "}
                      </div>
                    ) : (
                      "Next"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default SignupStep4;
