import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getItem } from "../common/reusable";
const PrivateRoutes = () => {
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const userType = JSON.parse(localStorage.getItem("userType"));
  return Userid ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
