import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer3 from "../../common/Footer3";
import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function Welcome() {
  const navigate = useNavigate();
  useEffect(() => {
    const userType = JSON.parse(localStorage.getItem("userType"));
    if (userType != null) {
      if (userType === "Investor") {
        navigate("/");
      } else {
        window.scroll(0, 0);
      }
    }
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Welcome</title>
      </Helmet>
      <section className="welcome-sec">
        <div className="container">
          <div className="top-comman-text">
            <div className="logo">
              <a href="/">
                <img src="src/images/site-logo.svg" alt="sitelogo" />
              </a>
              <h4>Welcome to Your Startup Dashboard</h4>
              <p>
                Thank you for listing your business on{" "}
                <b className="btext">Udharaa.com.</b> You are now part of a
                vibrant community where you have the opportunity to be contacted
                directly by investors. We will review your profile and make a
                decision on approval within 2 business days. Please monitor your
                email for updates. Once approved, your listing will go live and
                be visible to investors.
              </p>

              <div className="btn-box flex align-center space-center">
                <Link
                  to="/startup-profile"
                  className="cta-btn blue-btn Startup-btn startup-custom"
                >
                  Startup journey begins here!
                  <img src="src/images/arrow.svg" alt="icon" />
                </Link>
              </div>
              <figure className="welcome-img">
                <img src="src/images/welcome-bg.svg" alt="welcome-bg" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default Welcome;
