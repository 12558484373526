/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Footer2 from "../../common/Footer2";
import Header2 from "../../common/Header2";
import { apiurl } from "../Url";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { investordashboard } from "../../Routes/Routes";
import { get } from "../../services/services";
function InvestorPrivacydownload() {
  const [privacy, setPrivacy] = useState("");
  const [privacylocation, setPrivacylocation] = useState({});
  const [userid, setUserid] = useState(localStorage.getItem("UserId"));
  const [loading, Setloading] = useState(true);
  
  useEffect(() => {
    const cleanUserid = String(userid).replace(/['"]+/g, "");
    // let apifetchurl;

    // if (userType != "StartUp") {
    //   apifetchurl = `${apiurl}investors/getContractPdf/${cleanUserid}`;
    // } else {
    //   apifetchurl = `${apiurl}startups/getContractPdf/${cleanUserid}`;
    // }
  
      get(`${apiurl}investors/getContractPdf/${cleanUserid}`)
      .then((response) => {
        if (!response) {
          
        } else {
          const locationData = JSON.parse(response.data.data.location);
          setPrivacylocation(locationData);
          setPrivacy(response.data.data);
          Setloading(false);
        }
      
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const downloadPDF = async() => {
    const pdfElement = document.querySelector("#privacy-content");
  
    html2canvas(pdfElement, {
      scale:2,
      backgroundColor: "#ffffff",
    }).then((canvas) => {
      
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210;
      const pageHeight = 297;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      const doc = new jsPDF("p", "mm", "a4");
      let position = 10;
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      
      while (imgHeight > pageHeight) {
        imgHeight -= pageHeight;
        position -= pageHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      }
      doc.save("privacy_contractual_agreement.pdf");
    });
  };

  // useEffect(() => {
  //   const userType = JSON.parse(localStorage.getItem("userType"));
  //   if (userType != null) {
  //     if (userType != "Investor") {
  //       navigate("/");
  //     }
  //   }
  // }, []);

  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Privacy contract</title>
      </Helmet>
      <div className="bredcrum-sec">
        <div className="container-sm">
          <div className="bredcrum-box flex align-center">
            <div className="left-box">
              <h5>Non Circumvention Contract</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={investordashboard}>Startups business List</Link>
                </li>
                <li>Non Circumvention Contract</li>
              </ul>
            </div>
            <button className="cta-btn" onClick={downloadPDF}>
              Download as PDF
            </button>
          </div>
        </div>
      </div>
      <section className="privacy-Sec" id="privacy-content">
        <div className="container-sm">
          <div className="content-box">
            <div className="content-inner-box">
            <div className="logopctop">
              <img src="src/images/pctop.png" alt="" />
              </div>
              <div className="logopcbottom">
              <img src="src/images/pcbottom.png" alt="" />
              </div>
              <div className="logo">
                <a href="/">
                  <img src="src/images/site-logo.svg" alt="" />
                </a>
              </div>
              <h2>{privacy?.title}</h2>

              <p
                dangerouslySetInnerHTML={{
                  __html: privacy?.message,
                }}
              ></p>
              <h4>Signature </h4>
              <div
                style={{
                  borderBottom: "none",
                  paddingBottom: "10px",
                  fontSize: "20px",
                }}
                className="sign-box"
              >
                <img className="retry downloadsign" 
                  src={`data:image/png;base64,${privacy?.base64}`} alt="" />
              </div>
              <div className="privacycontract-ip">
                <h4>Ip address:</h4>
                <p>{privacy?.ip}</p>
              </div>
              <div className="privacycontract-ip">
                <h4>User location:</h4>
                <p>{privacylocation?.region}</p>
              </div>
              <div className="privacycontract-ip">
                <h4>Date and Time:</h4>
                <p>{privacy?.timestamp}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default InvestorPrivacydownload;
