/* eslint-disable eqeqeq */
import { axiosInstance } from './axios'

export const get = async (url, params = {}) => {
  try {
    const response = await axiosInstance.get(
      url,
      { params }
    )
    return response
  } catch (error) {
    throw error
  }
}

export const post = async (url, data = {}, headers = {}) => {
  if (headers?.headers == undefined) {
    headers = {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  }
  try {
    const response = await axiosInstance.post(url, data, headers)
    return response
  } catch (error) {
    throw error
  }
}

export const del = async (url) => {
  try {
    const response = await axiosInstance.delete(url);
    return response;
  } catch (error) {
    throw error;
  }
};
