import React, { useEffect, useState } from "react";
import Header2 from "../../common/Header2";
import Header from "../../common/Header";
import Footer2 from "../../common/Footer2";
import { getItem } from "../../common/reusable";
import axios from "axios";
import { apiurl } from "../Url";
import Loader from "../../common/Loader";
import { Helmet } from "react-helmet";
import { Link,useNavigate} from "react-router-dom";

function AboutUs() {
  const [aboutdata, SetAboutdata] = useState([]);
  const [loading, Setloading] = useState(true);
  const navigate=useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    axios
      .get(`${apiurl}visitor/getAbout`)
      .then((response) => {
        
        SetAboutdata(response?.data?.data);
        Setloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const gotoSignpage = e => {
    e.preventDefault();
    if (getItem("userType") != "") {
      if (getItem("userType") != "StartUp") {
        navigate("/investor-dashboard");
      } else {
        navigate("/messenger");
      }
    } else {
      navigate("/signup");
    }
  };

  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      {getItem("token") != "" ? <Header2 /> : <Header />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || About us</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="aboutus-page-sec home-banner-sec">
        <div className="container">
          <div className="bredcrum-box flex align-center">
            <div className="left-box">
              <h5>About</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>About</li>
              </ul>
            </div>
          </div>
          <div className="row flex">
            <div className="col-50">
              <div className="text">
                <h1>
                  {aboutdata?.aboutTitle && aboutdata?.aboutTitle.split(" ")[0]}
                  <span className="line">
                    {aboutdata?.aboutTitle &&
                      aboutdata?.aboutTitle.split(" ")?.slice(1).join(" ")}
                  </span>
                </h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: aboutdata?.aboutDetail,
                  }}
                ></p>
                <div className="btn-wrap-box">
                  <a  className="cta-btn btn-orange" onClick={gotoSignpage}>
                    Get Started
                  </a>
                </div>
                <div className="animation_box">
                  <div className="trangle">
                    <img src="src/images/trangle.svg" alt="icon" />
                  </div>

                  <div className="icon fill-cricle">
                    <img
                      src="src/images/about-small-cricle.svg"
                      alt=""
                      className="circleimage"
                    />
                  </div>
                  <div className="trangle rotate">
                    <img src="src/images/trangle.svg" alt="icon" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-50">
              {aboutdata?.aboutImage &&   <figure>
                <img
                  src={`${aboutdata?.aboutImage}`}
                 
                  className="full-img"
                />
              </figure>}
            
            </div>
          </div>
          <div className="our-story">
            <div className="text-box">
              <h2>
                {aboutdata?.storyTitle && aboutdata?.storyTitle.split(" ")[0]}
                <span className="line">
                  {aboutdata?.storyTitle &&
                    aboutdata?.storyTitle.split(" ")?.slice(1).join(" ")}
                </span>
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: aboutdata?.storyDetail,
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>

      <section className="our__mission">
        <div className="container">
          <div className="row flex">
            <div className="img-box">
              <figure>
                <img src={`${aboutdata?.missionImage}`} alt="" />
              </figure>
            </div>
            <div className="left-box">
              <h2>
                {aboutdata?.missionTitle &&
                  aboutdata?.missionTitle.split(" ")[0]}
                <span className="line">
                  {aboutdata?.missionTitle &&
                    aboutdata?.missionTitle.split(" ")?.slice(1).join(" ")}
                </span>
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: aboutdata?.missionDetail,
                }}
              ></p>
              <a  className="cta-btn btn-blue" onClick={gotoSignpage}>
                Join Now <img src="src/images/arrow.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="opportunity-sec our_team">
        <div className="container">
          <div className="top-comman-text text-center">
            <h2>
              What<span className="line">We Do</span>
            </h2>
          </div>
          <div className="content-row">
            <div className="box">
              <figure>
                <img src={`${aboutdata?.connectImage}`} alt="opportunity img" />
              </figure>
              <h5>{aboutdata?.connectTitle}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: aboutdata?.connectDetail,
                }}
              ></p>
            </div>
            <div className="box">
              <figure>
                <img src={`${aboutdata?.supportImage}`} alt="opportunity img" />
              </figure>
              <h5>{aboutdata?.supportTitle}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: aboutdata?.supportDetail,
                }}
              ></p>
            </div>
            <div className="box">
              <figure>
                <img
                  src={`${aboutdata?.facilitateImage}`}
                  alt="opportunity img"
                />
              </figure>
              <h5>{aboutdata?.facilitateTitle}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: aboutdata?.facilitateDetail,
                }}
              ></p>
            </div>
            {/* <div className="box">
              <figure>
                <img src="src/images/our-team04.png" alt="opportunity img" />
              </figure>
              <h5> James Smith</h5>
              <p>
                James is an expert in business development and strategic
                partnerships. With a background in venture capital, he has
                helped...
              </p>
              <div className="social-icon">
                <a href="#">
                  <img src="src/images/Facebook.svg" alt="" />
                </a>
                <a href="#">
                  <img src="src/images/twitter.svg" alt="" />
                </a>
                <a href="#">
                  <img src="src/images/insta.svg" alt="" />
                </a>
                <a href="#">
                  <img src="src/images/Linkedin.svg" alt="" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="news-letter-sec about-news-sec">
        <div className="container">
          <div className="top-comman-text text-center">
            <h2>
              {aboutdata?.joinTitle && aboutdata?.joinTitle.split(" ")[0]}
              <span className="line">
                {aboutdata?.joinTitle &&
                  aboutdata?.joinTitle.split(" ")?.slice(1).join(" ")}
              </span>
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: aboutdata?.joinDetail,
              }}
            ></p>
            <a  className="cta-btn btn-blue" onClick={gotoSignpage}>
              Join Now <img src="src/images/arrow.svg" alt="icon" />
            </a>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default AboutUs;
