/* eslint-disable eqeqeq */
import { apiurl } from "../maincomponent/Url";
import { getItem } from "./reusable";
import { get } from "../services/services";

const Profile = async () => {
  const userId = getItem("UserId");
  const userType = getItem("userType");
  
  try {
    const response = await get(
      userType != "StartUp"
        ? `${apiurl}users/getInvestorProfile/${userId}`
        : `${apiurl}startups/${userId}`
    );
    
    return userType != "StartUp"
      ? response?.data?.user
      : response?.data?.startups;
  } catch (err) {
    console.error(err);
    return null; // Return null or an appropriate default value in case of error
  }
};

export default Profile;
