/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { ChatList } from "react-chat-elements";
import "./Chatsidebar.css";
import { ChatContext } from "../../../Context/ChatContext";
// import SidebarArrow from "../../../../public/src/Vector.png";

const ChatSidebar = ({ chats, onChatSelect }) => {
  const { socket, unreadCounts } = useContext(ChatContext);
  const [activeTab, setActiveTab] = useState("all");

  const getUnreadCount = messages => {
    if (messages && messages.length > 0) {
      return messages.filter(
        a =>
          a.senderId != localStorage.getItem("UserId")?.replaceAll('"', "") &&
          !a.readAt
      ).length;
    } else return 0;
  };

  const handleTabClick = tab => {
    setActiveTab(tab);
  };

  const handleChatClick = chatId => {
    let c = chats?.find(chat => chat?._id === chatId);
    if (c?.messages?.length > 0) {
      let unreadMessages = c.messages.filter(
        a =>
          a.senderId != localStorage.getItem("UserId")?.replaceAll('"', "") &&
          !a.readAt
      );
      if (unreadMessages.length > 0) {
        let messagesToUpdate = unreadMessages.map(m => ({
          _id: m._id,
          readAt: new Date(),
        }));

        let payload = {
          messages: messagesToUpdate,
          senderId: localStorage.getItem("UserId")?.replaceAll('"', ""),
          receiverId: c.members.find(
            a => a._id != localStorage.getItem("UserId")?.replaceAll('"', "")
          )?._id,
        };
        socket.emit("updateMessage", payload);
      }
    }

    onChatSelect(chatId);
  };

  const filteredChats =
    activeTab === "unread"
      ? chats.filter(chat => {
          const unreadMessages = chat?.messages.filter(
            message =>
              message.senderId !==
                localStorage.getItem("UserId")?.replaceAll('"', "") &&
              !message.readAt
          );
          return unreadMessages.length > 0;
        })
      : chats;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const chatContainerStyle = {
    width: windowWidth < 450 ? "100%" : "367px",
  };

  return (
    <div className="left-sidebar" style={chatContainerStyle}>
      <div className="tab">
        <ul className="flex align-center space-between">
          <div className="flex">
            <li className={`flex`} onClick={() => handleTabClick("all")}>
              <span style={activeTab === "all" ? { } : {color: "black" }}>
                All
              </span>
            </li>
            <li className={`flex`} onClick={() => handleTabClick("unread")}>
              <span style={activeTab === "unread" ? {  } : {color: "black"}}>
                Unread({unreadCounts})
              </span>
            </li>
          </div>
          <li
            className="flex"
            style={{ justifySelf: "flex-end" }}
            onClick={() => onChatSelect(null)}
          >
            <img src="src/images/Icon20.png" alt="icon" />
          </li>
        </ul>
      </div>
      <div className="">
        {filteredChats
          .sort((a, b) => {
            const aPinned = a?.pinChat.includes(
              localStorage.getItem("UserId")?.replaceAll('"', "")
            );
            const bPinned = b?.pinChat.includes(
              localStorage.getItem("UserId")?.replaceAll('"', "")
            );
            return bPinned - aPinned; // Sort in descending order, so pinned chats come first
          })
          .map(_chat => {
            const chat = _chat?.members.find(
              member =>
                member._id !=
                localStorage.getItem("UserId")?.replaceAll('"', "")
            );

            const pinned = _chat?.pinChat.includes(
              localStorage.getItem("UserId")?.replaceAll('"', "")
            );

            const chatData = {
              id: _chat?._id,
              avatar: chat?.profile_img
                ? chat?.profile_img
                : "src/images/deletedUser.png",
              alt: chat?.title,
              title: chat?.name ? chat?.name : "User Deleted",
              subtitle: _chat?.messages
                ? _chat?.messages[_chat?.messages.length - 1]?.file
                  ? "Attachment"
                  : _chat?.messages[_chat?.messages.length - 1]?.text
                : "Type Message",
              date:
                _chat?.messages && _chat?.messages.length > 0
                  ? new Date(_chat?.lastMessage?.createdAt)
                  : "",
              unread: getUnreadCount(_chat?.messages),
            };

            return (
              <div
                key={chatData.id}
                className="chat-item-container"
                style={{ position: "relative" }}
              >
                <ChatList
                  onClick={() => handleChatClick(chatData.id)}
                  className="chat-list"
                  dataSource={[chatData]} // Single item array to fit ChatList structure
                />
                {pinned && (
                  <img
                    style={{
                      position: "absolute",
                      zIndex: "2",
                      right: "10px",
                      top: "28px",
                    }}
                    src="src/images/pinnedsign.png"
                    alt="pin_icon"
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ChatSidebar;
