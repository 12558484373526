/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import { apiurl } from "../Url";
import Footer3 from "../../common/Footer3";
import { privacypolicy } from "../../Routes/Routes";
import Loaderbutton from "../../common/loaderbutton";

function InvestorSignup() {
  const navigate = useNavigate();
  const [loading,setLoading]=useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
    acceptPolicy: false,
  });
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const accountType = params.get("accountType");
const [call,setCall]=useState(false);
const [calltoken,setCalltoken]=useState("");
  console.log(accountType, "test hai");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        setErrors({ ...errors, [name]: "Only Alphabets are allowed" });
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.password) {
      newErrors.password = "Password is required.";
    } else if (formData.password.length < 8) {
      newErrors.password = "Please enter more then 8 character.";
    }
    if (!formData.repeatPassword) {
      newErrors.repeatPassword = "Repeat Password is required.";
    } else if (formData.repeatPassword !== formData.password) {
      newErrors.repeatPassword = "Passwords do not match.";
    }
    if (!formData.acceptPolicy) {
      newErrors.acceptPolicy = "Please accept the Privacy Policy.";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true)
      const data = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.repeatPassword,
        userType: accountType,
      };
      axios
        .post(`${apiurl}users`, data)
        .then((response) => {
          

          if (
            response.data.status === true 
          ) {
            localStorage.setItem("token", JSON.stringify(response.data.token));
            localStorage.setItem(
              "UserId",
              JSON.stringify(response.data.lastUser?._id)
            );
            localStorage.setItem(
              " userType",
              JSON.stringify(response.data.lastUser?.userType)
            );
            
            navigate("/personal-investment-profile");
          } else if (response.data.message == "Email already registered") {
            toast.error("user already exists");
            setLoading(false)
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.message);
        });
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // const GoogleLogin = useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     console.log(tokenResponse);
  //     navigate("/personal-investment-profile");
  //   },
  //   onFailure: (error) => {
  //     console.error(error);
  //   },
  // });
  const GoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      
      try {
        // Fetch user information from Google
        const userInfoResponse = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`
        );
        const userInfo = userInfoResponse.data;
        

        // Send the token to your backend
        const response = await axios.post(`${apiurl}users/googlesignup`, {
          google: userInfo,
          userType: accountType,
        });

        const result = response.data;
        
        if (result?.status == true) {
          localStorage.setItem(
            "token",
            JSON.stringify(result?.lastUser?.token)
          );
          localStorage.setItem("UserId", JSON.stringify(result?.lastUser?._id));
          localStorage.setItem(
            " userType",
            JSON.stringify(result?.lastUser?.userType)
          );
          navigate("/personal-investment-profile");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.error(error);
      }
    },
    onFailure: (error) => {
      console.error(error);
    },
  });
  const Gotologin = () => {
    navigate("/login");
  };
  const fetchProfileData = async (accessToken) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      linkedIn:accessToken,
      userType:accountType,
      redirect_uri:`${window.location.origin}/linkedin`,
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch(`${apiurl}users/linkedInsignup`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
       
        if (result?.status == true) {
          setCall(false);
          localStorage.setItem(
            "token",
            JSON.stringify(result?.lastUser?.token)
          );
          localStorage.setItem("UserId", JSON.stringify(result?.lastUser?._id));
          localStorage.setItem(
            " userType",
            JSON.stringify(result?.lastUser?.userType)
          );
          navigate("/personal-investment-profile");
        } else {
          toast.error(result.message);
          setCall(false);
        }
      })
      .catch((error) => console.error(error));
  };
  const { linkedInLogin } = useLinkedIn({
    clientId: "77yms1uz2h8tx2",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid profile email",
    onSuccess: (response) => {
      
      setCall(true)
      setCalltoken(response)
      // fetchProfileData(response);
   
    },
    onError: (error) => {
      console.log(error);
  
    },
  });
  useEffect(()=>{
    if(call){
      fetchProfileData(calltoken);
    }
   
  },[call])
 
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Investor signup</title>
      </Helmet>
      <section className="create-account-sec">
        <div className="container">
          <div className="row flex">
            <div className="img-box">
              <Link to="/">
                <img src="src/images/logo-second.svg" alt="" />
              </Link>
              <h5>Join the Investment Community</h5>
              <p>
                Create an <a href="#">Investor Account</a> to explore investment{" "}
                <a href="#">opportunities</a>, connect with{" "}
                <a href="#">promising startups</a>, and diversify your
                portfolio. Gain access to a wealth of resources and insights to
                make informed <a href="#">investment decisions.</a>
              </p>
            </div>
            <div className="form-box">
              <div className="form-wrapper">
                <div className="top-comman-text text-center">
                  <h4>Create an account</h4>
                  <p>Your social campaigns</p>
                </div>
                <ul className="signup-method flex">
                  <li onClick={GoogleLogin}>
                    <img src="src/images/Google.svg" alt="" />
                    Sign up with Google
                  </li>
                  <li onClick={linkedInLogin}>
                    <img src="src/Linkedin1.png" alt="" />
                    Sign up with LinkedIn
                  </li>
                  <a className="with-mail">
                    <span>Or with email</span>
                  </a>
                </ul>
                <form>
                  <div className="form-group">
                    <label htmlFor="">
                      Name<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder="John Doe"
                      className="form-control"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && <p className="error-msg">{errors.name}</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Email<span className="required-sign">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="example@udharaa.com"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="error-msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Password<span className="required-sign">*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      placeholder="************"
                      className="form-control"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <p className="error-msg">{errors.password}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Repeat Password<span className="required-sign">*</span>
                    </label>
                    <input
                      type="password"
                      name="repeatPassword"
                      placeholder="************"
                      className="form-control"
                      value={formData.repeatPassword}
                      onChange={handleChange}
                    />
                    {errors.repeatPassword && (
                      <p className="error-msg">{errors.repeatPassword}</p>
                    )}
                  </div>
                  <div className="form-group checkbox">
                    <label className="flex">
                      I Accept the{" "}
                      <Link to={privacypolicy}>Privacy Policy</Link>
                      <input
                        type="checkbox"
                        name="acceptPolicy"
                        checked={formData.acceptPolicy}
                        onChange={handleChange}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {errors.acceptPolicy && (
                      <p className="error-msg">{errors.acceptPolicy}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <Link onClick={handleSubmit}>
                      <button className="cta-btn">  {loading ? (
                        <div>
                          <Loaderbutton/>{" "}
                        </div>
                      ) : (
                        "Sign up"
                      )}</button>
                    </Link>
                  </div>
                  <div className="form-group sign-in">
                    <p>
                      Already have an Account?{" "}
                      <a
                        href="/login"
                        onClick={(e) => {
                          e.preventDefault();
                          Gotologin(e);
                        }}
                      >
                        Login
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default InvestorSignup;
