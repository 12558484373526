/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { socketUrl } from "../maincomponent/Url";
import { toast } from "react-toastify";
import { removeItem } from "../common/reusable";

export const ChatContext = createContext();

export const ChatContextProvoder = ({ children, user }) => {
  const [userChats, setUserChats] = useState([]);
  const [firstTimeSelectedChatId, setFirstTimeSelectedChatId] = useState();
  const [unreadCounts, setUnreadCounts] = useState(0);

  const [socket, setSocket] = useState(null);
  const [socketID, setSocketID] = useState(null);
  const [contractSigned, setContractSigned] = useState(true);
  const [startConnection, setStartConnection] = useState(false);
  const [isUserOnline, setIsUserOnline] = useState(false);

  useEffect(() => {
    let newSocket = "";
    if (
      (localStorage.getItem("UserId") && startConnection) ||
      localStorage.getItem("UserId")
    ) {
      newSocket = io(socketUrl, {
        path: "/socket",
        // reconnection: true,
        transports: ["websocket", "polling"],
        // reconnectionAttempts: 5
        withCredentials: true,
      });
      setSocket(newSocket);
    }

    return () => {
      if (newSocket) {
        newSocket?.disconnect();
      }
    };
  }, [startConnection]);

  useEffect(() => {
    socket?.on("connect", () => {
      setSocketID(socket?.id);
    });

    socket?.on("disconnect", () => {
      setSocketID(null);
    });
  }, [socket]);

  useEffect(() => {
    if (socket == null || socketID == null) return;
    if (localStorage.getItem("token")) {
      socket.emit("getChats", {
        token: localStorage.getItem("token")?.replaceAll('"', ""),
        id: firstTimeSelectedChatId
          ? firstTimeSelectedChatId?.replaceAll('"', "")
          : null,
        socketId: socketID,
      });
    }
  }, [socket, firstTimeSelectedChatId, socketID]);

  useEffect(() => {
    if (socket == null) return;
    socket.emit(
      "addNewUser",
      localStorage.getItem("UserId")?.replaceAll('"', "")
    );
    socket.on("getChatsResponse", res => {
      if (res?.chats == "invalidToken") {
        setUserChats([]);
        toast.error("Session Expired");
        removeItem("token");
        removeItem("userType");
        removeItem("UserId");
        removeItem("url");
        setTimeout(() => {
          window.location.href = "/login";
        }, 1500);
      } else {
        setContractSigned(res?.isContractSigned);
        setUserChats(res?.chats);
      }
    });

    return () => {
      socket?.off("getChatsResponse");
    };
  }, [socket]);

  useEffect(() => {
    if (socket == null) return;
    socket.on("NewMessageArrived", res => {
      setUserChats(res);
    });

    socket.on("ResponseOfUserOnlineStatus", res => {
      setIsUserOnline(res);
    });

    return () => {
      socket?.off("NewMessageArrived");
      socket?.off("ResponseOfUserOnlineStatus");
    };
  }, [socket]);

  useEffect(() => {
    let totalUnread = 0;
    userChats.forEach(chat => {
      totalUnread += getUnreadCount(chat?.messages);
    });
    setUnreadCounts(totalUnread);
  }, [userChats]);

  const getUnreadCount = messages => {
    if (messages && messages.length > 0) {
      return messages.filter(
        a =>
          a.senderId != localStorage.getItem("UserId")?.replaceAll('"', "") &&
          !a.readAt
      ).length;
    } else return 0;
  };

  return (
    <ChatContext.Provider
      value={{
        userChats,
        firstTimeSelectedChatId,
        setFirstTimeSelectedChatId,
        socket,
        contractSigned,
        setStartConnection,
        setContractSigned,
        unreadCounts,
        isUserOnline,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
