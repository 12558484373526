/* eslint-disable eqeqeq */
import axios from 'axios'
import { apiurl } from '../maincomponent/Url'
import { toast } from 'react-toastify'
import { removeItem } from '../common/reusable'
export const axiosInstance = axios.create({
  baseURL: apiurl,
  // timeout: 10000,s
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token')?.replaceAll('"','')

    if (token) {
      if (config.method != 'post') {
        config.headers['Content-Type'] = 'application/json'
      }
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    if(response.data && response?.data?.message == "Unauthorized!"){
      toast.error('Session Expired');
      removeItem("token");
      removeItem("userType");
      removeItem("UserId");
      removeItem("url");
      setTimeout(()=>{
        window.location.href="/login";
      },1500)

    
    
      return false
    } else return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error)
  }
)

