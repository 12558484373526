export const states = [
  "Andhra Pradesh",
  "Tamil Nadu",
  "Kerala",
  "Karnataka",
  "Maharashtra",
  "Telangana",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Madhya Pradesh",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
export const Industries = [
  "Technology",
  "Brick & Mortor",
  "Consumer Goods",
  "Science and R&D",
  "Entertainment",
  "Healthcare",
  "Education",
  "Energy",
  "Other",
];

export const GeneralQuestions = [
  "Search Engine (e.g., Google, Bing)",
  " Social Media (e.g., Facebook, Instagram)",
  " Friend or Family",
  "Online Advertisement",
  " News Article or Blog",
  " Email Campaign",
  " Event or Webinar",
  " YouTube",
  " Podcast",
  " Other (please specify)",
];
