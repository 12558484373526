/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import Header from "../common/Header";
import Header2 from "../common/Header2";
// import Footer from "../common/Footer";
import Section from "./Section/Section";
import Footer2 from "../common/Footer2";
import { getItem } from "../common/reusable";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
function Home() {

  const navigate = useNavigate();

  useEffect(() => {
    let url = localStorage.getItem("url")?.replaceAll('"',"")
    if((localStorage.getItem("token") && url == "/startup-profile") || (localStorage.getItem("token") && url == "/investor-dashboard")) {
    } else {
      navigate(url)
    }
  }, []);
  

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Home</title>
      </Helmet>
      {getItem("token") != "" ? <Header2 /> : <Header />}
      <Section />
      <Footer2 />
    </>
  );
}
export default Home;
