export const Loaderbutton = () => {
    return (
      <>
        <div
          style={{ justifyContent: "center", width: "23px", marginLeft: "45%" }}
          className="flex align-center"
        >
          <div className="loader loaderbutton"></div>
        </div>
      </>
    );
  };
  export default Loaderbutton;