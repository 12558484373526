import React, { useEffect, useState } from "react";
import Header2 from "../../common/Header2";
import Header from "../../common/Header";
import { getItem } from "../../common/reusable";
import Footer2 from "../../common/Footer2";
import { apiurl } from "../Url";
import axios from "axios";
import Loader from "../../common/Loader";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
function Terms() {
  const [termsConditions, setTermsConditions] = useState("");
  const [loading, Setloading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${apiurl}visitor/getTerms`)
      .then((response) => {
        
        setTermsConditions(response.data.data);
        Setloading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
       <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      {getItem("token") != "" ? <Header2 /> : <Header />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Terms & conditions</title>
      
      </Helmet>
      <section className="dashboard-sec term-condition-page">
        <div className="container">
          <div className="bredcrum-box flex align-center space-between">
            <div className="left-box">
              <h5>Terms & Conditions</h5>
              <ul className="flex">
                <li className="tremsbreada">
                  <Link to="/">Home</Link>
                </li>
                <li className="tremsbread">Terms & Conditions</li>
              </ul>
            </div>
            {/* <div className="right-grid-box">
              <ul className="flex">
                <li className="active" data-tab="1">
                  <img src="src/images/gridicon01.svg" alt="" />
                </li>
                <li data-tab="2">
                  <img src="src/images/gridicon02.svg" alt="" />
                </li>
              </ul>
            </div> */}
          </div>
          <div className="row-box">
            <div className="shadow-box">
              <h3>Terms & Conditions</h3>
              <p
                dangerouslySetInnerHTML={{ __html: termsConditions.message }}
              />
              {/* <p>
                Welcome to Udharaa These Terms & Conditions govern your use of
                our platform and services. By accessing or using our platform,
                you agree to comply with and be bound by these terms. If you do
                not agree with any part of these terms, you must not use our
                services.
              </p>
              <h6>Platform</h6>
              <p>Refers to the website and services provided by Udharaa.</p>
              <h6>User</h6>
              <p>
                Refers to anyone who accesses or uses our platform, including
                investors and startups.
              </p>
              <h6>Content</h6>
              <p>
                Refers to all text, graphics, videos, and other materials
                available on the platform.
              </p>
              <h6>Content</h6>
              <p>
                Refers to the various functionalities and offerings provided by
                [Your Platform Name].
              </p>
              <h3>Eligibility</h3>
              <p>
                To use our platform, you must be at least 18 years old and
                capable of entering into a legally binding agreement. By using
                our services, you represent and warrant that you meet these
                requirements.
              </p>
              <h3>User Accounts</h3>
              <h6>Registration</h6>
              <p>
                To access certain features, you must create an account. You
                agree to provide accurate and complete information during the
                registration process and to update your information as
                necessary.
              </p>
              <h6>Account Security</h6>
              <p>
                You are responsible for maintaining the confidentiality of your
                account credentials and for all activities that occur under your
                account. You agree to notify us immediately of any unauthorized
                use of your account.
              </p>
              <h3>Use of Services</h3>
              <h6>Permitted Use</h6>
              <p>
                You may use our services only for lawful purposes and in
                accordance with these terms. You agree not to use the platform:
              </p>
              <ol>
                <li>
                  In any way that violates applicable laws or regulations.
                </li>
                <li>
                  To exploit, harm, or attempt to exploit or harm minors in any
                  way.
                </li>
                <li>
                  To transmit or procure the sending of any advertising or
                  promotional material without our prior consent.
                </li>
              </ol>
              <h6>Prohibited Use</h6>
              <p>You agree not to:</p>
              <ol>
                <li>
                  Use the platform in any manner that could disable, overburden,
                  damage, or impair the platform.
                </li>
                <li>
                  Use any robot, spider, or other automatic devices, process, or
                  means to access the platform for any purpose.
                </li>
                <li>
                  Introduce any viruses, Trojan horses, worms, or other material
                  that is malicious or technologically harmful.
                </li>
              </ol>
              <h3>Content</h3>
              <h6>User Content</h6>
              <p>
                You retain ownership of the content you post on our platform. By
                posting content, you grant us a non-exclusive, royalty-free,
                worldwide license to use, reproduce, modify, and display your
                content in connection with our services.
              </p>
              <h6>Prohibited Content</h6>
              <p>You agree not to post any content that:</p>
              <ul>
                <li>Is defamatory, obscene, or offensive.</li>
                <li>
                  Infringes on the intellectual property rights of others.
                </li>
                <li>Violates the privacy or publicity rights of others.</li>
              </ul>
              <h3>Privacy Policy</h3>
              <p>
                Your use of our platform is also governed by our Privacy Policy.
                Please review our Privacy Policy to understand our practices.
              </p>
              <h3>Payments and Fees</h3>
              <h6>Service Fees</h6>
              <p>
                Certain services may require payment of fees. All fees are
                non-refundable unless otherwise stated. You agree to pay all
                applicable fees and charges in accordance with our payment
                terms.
              </p>
              <h6>Taxes</h6>
              <p>
                You are responsible for all applicable taxes arising from your
                use of our services.
              </p>
              <h3>Intellectual Property</h3>
              <h6>Ownership</h6>
              <p>
                All intellectual property rights in the platform and its content
                are owned by us or our licensors. You may not use, reproduce, or
                distribute any content without our prior written consent.
              </p>
              <h6>Trademarks</h6>
              <p>
                Udharaa and all related names, logos, product and service names,
                designs, and slogans are trademarks of [Your Company Name] or
                its affiliates. You may not use such marks without our prior
                written consent.
              </p>
              <h3>Disclaimers</h3>
              <p>
                The platform and services are provided "as is" and "as
                available" without any warranties of any kind, either express or
                implied. We do not warrant that the platform will be
                uninterrupted or error-free.
              </p>
              <h3>Terms & Conditions</h3>
              <p>
                To the fullest extent permitted by law, we shall not be liable
                for any indirect, incidental, special, consequential, or
                punitive damages arising out of or in connection with your use
                of the platform or services.
              </p>
              <h3>Indemnification</h3>
              <p>
                You agree to indemnify, defend, and hold harmless [Your Company
                Name], its affiliates, and their respective officers, directors,
                employees, and agents from and against any claims, liabilities,
                damages, judgments, awards, losses, costs, expenses, or fees
                arising out of or relating to your violation of these terms or
                your use of the platform.
              </p>
              <h3>Governing Law</h3>
              <p>
                These terms are governed by and construed in accordance with the
                laws of [Your Country/State], without regard to its conflict of
                law principles. You agree to submit to the exclusive
                jurisdiction of the courts located in [Your Jurisdiction] for
                the resolution of any disputes.
              </p>
              <h3>Changes to Terms</h3>
              <p>
                We reserve the right to modify these terms at any time. We will
                provide notice of any changes by updating the "Last Updated"
                date at the top of these terms. Your continued use of the
                platform after any changes constitutes your acceptance of the
                revised terms.
              </p> */}
              {/* <h3>Contact Us</h3>
              <p>
                If you have any questions or concerns about these terms, please
                contact us at:
              </p> */}
              {/* <div className="contact-links flex align-center">
                <a href="mailto:info@udharaa.com">
                  <img src="src/images/mail-icon.svg" alt="" /> info@udharaa.com
                </a>
                <a href="tel:882-587-3025">
                  <img src="src/images/call-icon.svg" alt="" />
                  882-587-3025
                </a>
                <p>
                  <img src="src/images/location-blue.svg" alt="" /> 6116 Willa
                  River Suite 610
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default Terms;
