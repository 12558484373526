import React, { useEffect, useState } from "react";
import Header2 from "../../common/Header2";
import Header from "../../common/Header";
import { getItem } from "../../common/reusable";
import Footer2 from "../../common/Footer2";
import { apiurl } from "../Url";
import axios from "axios";
import Loader from "../../common/Loader";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
function Privacy() {
  const [getPrivacy, setGetPrivacy] = useState("");
  const [loading, Setloading] = useState(true)
	useEffect(() => {
		window.scrollTo(0, 0);
		axios
		  .get(`${apiurl}visitor/getPrivacy`)
		  .then((response) => {
			
			setGetPrivacy(response.data.data);
      Setloading(false)
		  })
		  .catch((err) => {
			console.log(err);
		  });
	  }, []);

  return (
    <>
       <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      {getItem("token") != "" ? <Header2 /> : <Header />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Privacy policy</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="dashboard-sec term-condition-page">
        <div className="container">
          <div className="bredcrum-box flex align-center space-between">
            <div className="left-box">
              <h5>Privacy Policy</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Privacy Policy</li>
              </ul>
            </div>
            {/* <div className="right-grid-box">
              <ul className="flex">
                <li className="active" data-tab="1">
                  <img src="src/images/gridicon01.svg" alt="" />
                </li>
                <li data-tab="2">
                  <img src="src/images/gridicon02.svg" alt="" />
                </li>
              </ul>
            </div> */}
          </div>
          <div className="row-box">
            <div className="shadow-box">
              <h3>Privacy Policy</h3>
			  <p
                dangerouslySetInnerHTML={{ __html: getPrivacy.message }}
              />
              {/* <p>
                At [Your Platform Name], we are committed to protecting your
                privacy. This Privacy Policy explains how we collect, use,
                disclose, and safeguard your information when you visit our
                website [yourwebsite.com] and use our services. If you do not
                agree with the terms of this Privacy Policy, please do not
                access the site or use our services.
              </p>
              <h3>Information We Collect</h3>
              <h6>Personal Information</h6>
              <p>
                When you register on our platform or interact with our services,
                we may collect personal information that can identify you, such
                as:
              </p>
              <ul>
                <li>Name</li>
                <li>email address</li>
                <li>phone number</li>
                <li>mailing address</li>
                <li>payment information</li>
                <li>profile picture</li>
                <li>LinkedIn URL</li>
                <li>Professional background and investment interests</li>
              </ul>
              <h6>Non-Personal Information</h6>
              <p>
                We may also collect non-personal information that does not
                identify you, including:
              </p>
              <ul>
                <li>Browser type</li>
                <li>Operating system</li>
                <li>IP address</li>
                <li>Pages visited</li>
                <li>Time and date of vis</li>
                <li>Referring URL</li>
              </ul>
              <h3>How We Use Your Information</h3>
              <p>
                We use the information we collect for various purposes,
                including:
              </p>
              <ul>
                <li>
                  We use the information we collect for various purposes,
                  including:
                </li>
                <li>To improve, personalize, and expand our services</li>
                <li>To understand and analyze how you use our services</li>
                <li>
                  To develop new products, services, features, and functionality
                </li>
                <li>
                  To communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the services, and
                  for marketing and promotional purposes
                </li>
                <li>To process your transactions and manage your orders</li>
                <li>To send you emails</li>
                <li>To find and prevent fraud</li>
                <li>To comply with legal obligations</li>
              </ul>
              <h3>How We Share Your Information</h3>
              <p>We may share your information in the following situations:</p>
              <ul>
                <li>
                  <p>
                    <span>With Service Providers:</span> We may share your
                    information with third-party vendors, service providers,
                    contractors, or agents who perform services for us or on our
                    behalf and require access to such information to do that
                    work
                  </p>
                </li>
                <li>
                  <p>
                    <span>For Business Transfers:</span> We may share or
                    transfer your information in connection with, or during
                    negotiations of, any merger, sale of company assets,
                    financing, or acquisition of all or a portion of our
                    business to another company
                  </p>
                </li>
                <li>
                  <p>
                    <span>With Business Partners:</span> We may share your
                    information with our business partners to offer you certain
                    products, services, or promotions.
                  </p>
                </li>
                <li>
                  <p>
                    <span>With Other Users:</span> When you share personal
                    information or otherwise interact with public areas of the
                    platform, such information may be viewed by all users and
                    may be publicly distributed outside the platform.
                  </p>
                </li>
                <li>
                  <p>
                    <span>With Your Consent:</span> We may disclose your
                    personal information for any other purpose with your consent
                  </p>
                </li>
                <h3>Security of Your Information</h3>
                <p>
                  use administrative, technical, and physical security measures
                  to help protect your personal information. While we have taken
                  reasonable steps to secure the personal information you
                  provide to us, please be aware that despite our efforts, no
                  security measures are perfect or impenetrable, and no method
                  of data transmission can be guaranteed against any
                  interception or other type of misuse.
                </p>
              </ul>
              <h3>Your Data Protection Rights</h3>
              <p>
                Depending on your location, you may have the following rights
                regarding your personal data:
              </p>
              <ul>
                <li>
                  The Right to Access: You have the right to request copies of
                  your personal data.
                </li>
                <li>
                  The Right to Rectification: You have the right to request that
                  we correct any information you believe is inaccurate or
                  complete information you believe is incomplete.
                </li>
                <li>
                  The Right to Erasure: You have the right to request that we
                  erase your personal data, under certain conditions.
                </li>
                <li>
                  The Right to Restrict Processing: You have the right to
                  request that we restrict the processing of your personal data,
                  under certain conditions.
                </li>
                <li>
                  The Right to Object to Processing: You have the right to
                  object to our processing of your personal data, under certain
                  conditions.
                </li>
                <p>
                  The Right to Data Portability: You have the right to request
                  that we transfer the data that we have collected to another
                  organization, or directly to you, under certain conditions.
                </p>
                <li>
                  <p>
                    The Right to Data Portability: You have the right to request
                    that we transfer the data that we have collected to another
                    organization, or directly to you, under certain conditions.
                  </p>
                </li>
                <p>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us at our provided contact information.
                </p>
              </ul>
              <h3>Children's Privacy</h3>
              <p>
                Our services are not intended for use by children under the age
                of 18, and we do not knowingly collect personal information from
                children under 18. If we become aware that we have collected
                personal information from a child under age 18 without
                verification of parental consent, we will take steps to remove
                that information from our servers.
              </p>
              <h3>Changes to This Privacy Policy</h3>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
              </p>
              <h3>Contact Us</h3>
              <p>
                If you have any questions or concerns about these terms, please
                contact us at:
              </p>
              <div className="contact-links flex align-center">
                <a href="maito:info@udharaa.com">
                  <img src="src/images/mail-icon.svg" alt="" /> info@udharaa.com
                </a>
                <a href="tel:882-587-3025">
                  <img src="src/images/call-icon.svg" alt="" />
                  882-587-3025
                </a>
                <p>
                  <img src="src/images/location-blue.svg" alt="" /> 6116 Willa
                  River Suite 610
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default Privacy;
