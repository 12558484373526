/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { post, get } from "../../services/services";
import { setItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";

function InvestorSignupStep2() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [toottipbankstatement, SetTooltipbankstatement] = useState(false);
  const [toottiplicense, SetTooltiplicense] = useState(false);
  const [toottipaadharcard, SetTooltipaadharcard] = useState(false);

  const [formData, setFormData] = useState({
    passport: "",
    driversLicense: "",
    aadhaarCard: "",
  });
  const [fileNames, setFileNames] = useState({
    passport: "",
    driversLicense: "",
    aadhaarCard: "",
  });
  const [errors, setErrors] = useState({});
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const handleChange = e => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      const maxSize = 5 * 1024 * 1024;

      if (file.size > maxSize) {
        toast.warn("File size should not exceed 5MB", {
          position: "top-center",
        });
      } else {
        setFormData({
          ...formData,
          [name]: file,
        });
        setFileNames({
          ...fileNames,
          [name]: file.name,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (
      !formData.passport &&
      !formData.driversLicense &&
      !fileNames.passport &&
      !fileNames.driversLicense &&
      !formData.aadhaarCard &&
      !fileNames.aadhaarCard
    ) {
      newErrors.passport = "Submit one Document";
    }
    // if (!formData.aadhaarCard && !fileNames.aadhaarCard) {
    //   newErrors.aadhaarCard = "AadhaarCard is required.";
    // }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const data = new FormData();
      data.append("passport", formData.passport);
      data.append("drive_license", formData.driversLicense);
      data.append("aadhar_card", formData.aadhaarCard);

      post(`${apiurl}investors/investoIndetityVerification/${Userid}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setLoading(false);
            } else {
              setItem("url", "/financial-standing-accreditation");
              navigate("/financial-standing-accreditation");
            }
          }
        })
        .catch(error => {
          console.log(error.response);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}users/getInvestorProfile/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          setFileNames({
            passport: response?.data?.user?.passport,
            driversLicense: response?.data?.user?.drive_license,
            aadhaarCard: response?.data?.user?.aadhar_card,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const openTooltipbankstatement = () => {
    timerRef.current = setTimeout(() => SetTooltipbankstatement(true), 300);
    
  };

  const closeTooltipbankstatement = () => {
    clearTimeout(timerRef.current);
    SetTooltipbankstatement(false);
  };

  const openTooltiplicense = () => {
    timerRef.current = setTimeout(() => SetTooltiplicense(true), 300);
  };

  const closeTooltiplicense = () => {
    clearTimeout(timerRef.current);
    SetTooltiplicense(false);
  };
  const timerRef = useRef(null);
  const openTooltipaadharcard = () => {
    timerRef.current = setTimeout(() => SetTooltipaadharcard(true), 300);
  };

  const closeTooltipaadharcard = () => {
    clearTimeout(timerRef.current);
    SetTooltipaadharcard(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Identity verification</title>
      </Helmet>
      <section className="signUp-Sec">
        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex completed">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Personal and Investment Profile
                  </h4>
                  <p>Build Your Investment Profile</p>
                </div>
              </div>
              <div className="step-box flex active">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4>Identity Verification</h4>
                  <p>Verify Your Identity</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4>Financial Standing and Accreditation</h4>
                  <p>Confirm Your Financial Status</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4>Additional Information</h4>
                  <p>Enter Additional Information</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">Identity Verification</h5>
                <form action="">
                  <h5>
                    Stage of Business{" "}
                    <b>
                      {" "}
                      (Any one from below)
                      <span className="required-sign">*</span>
                    </b>
                  </h5>
                  <div className="form-group">
                    <div className="custom-file">
                      <ul>
                        <li
                          onMouseEnter={() => openTooltipbankstatement()}
                          onMouseLeave={() => closeTooltipbankstatement()}
                        >
                          <h6>Passport</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="passport"
                            name="passport"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg,.pdf"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="passport"
                          >
                            {fileNames.passport
                              ? fileNames.passport.length > 20
                                ? `${fileNames.passport.slice(0, 20)}...`
                                : fileNames.passport
                              : "Upload File"}
                          </label>
                        </li>
                        {toottipbankstatement && (
                          <div className="tooltip-container tooltipdocumentinvestorlicense">
                            <div className="tooltiptext">
                              <div className="tooltip-arrow"></div>
                              File type allowed- Png,Jpg,Jpeg,Svg,Pdf <br />
                              Maximum Size File 5Mb
                            </div>
                          </div>
                        )}
                        <li
                          onMouseEnter={() => openTooltiplicense()}
                          onMouseLeave={() => closeTooltiplicense()}
                        >
                          <h6>Driver’s License</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="addressproof"
                            name="driversLicense"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg,.pdf"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="addressproof"
                          >
                          
                              {fileNames.driversLicense
                                ? fileNames.driversLicense.length > 20
                                  ? `${fileNames.driversLicense.slice(
                                      0,
                                      20
                                    )}...`
                                  : fileNames.driversLicense
                                : "Upload File"}
                           
                          </label>
                        </li>
                        {toottiplicense && (
                          <div className="tooltip-container tooltipdocumentinvestorlicense">
                            <div className="tooltiptext">
                              <div className="tooltip-arrow"></div>
                              File type allowed - Png , Jpg , Jpeg , Svg , Pdf{" "}
                              <br />
                              Maximum Size File 5Mb
                            </div>
                          </div>
                        )}
                      </ul>
                      {errors.passport && (
                        <p className="error-msg">{errors.passport}</p>
                      )}
                      {errors.driversLicense && (
                        <p className="error-msg">{errors.driversLicense}</p>
                      )}
                    </div>
                  </div>
                  <div className="form-group full">
                    <div className="custom-file">
                      <ul>
                        <li
                          onMouseEnter={() => openTooltipaadharcard()}
                          onMouseLeave={() => closeTooltipaadharcard()}
                        >
                          <h6>Aadhaar Card</h6>
                          <small>
                            (for Indian investors) to verify the identity of the
                            investor
                          </small>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="addharcard"
                            name="aadhaarCard"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg,.pdf"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="addharcard"
                          >
                            {fileNames.aadhaarCard
                              ? fileNames.aadhaarCard.length > 20
                                ? `${fileNames.aadhaarCard.slice(0, 20)}...`
                                : fileNames.aadhaarCard
                              : "Upload File"}
                          </label>
                        </li>
                      </ul>
                      {toottipaadharcard && (
                        <div className="tooltip-container tooltipdocumentinvestor">
                          <div className="tooltiptext">
                            <div className="tooltip-arrow"></div>
                            File type allowed - Png, Jpg , Jpeg , Svg ,Pdf
                            <br />
                            Maximum Size File 5Mb
                          </div>
                        </div>
                      )}
                      {errors.aadhaarCard && (
                        <p className="error-msg">{errors.aadhaarCard}</p>
                      )}
                    </div>
                  </div>
                  {/* <div className="form-group full">
                    <div className="custom-file">
                      <h5>Recent Utility Bill</h5>
                      <p className="mb-12">
                        To confirm the residential address. The bill should be
                        recent, usually within the last three months.
                      </p>
                      <ul>
                        <li>
                          <h6>Utility Bill</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Utility"
                            name="utilityBill"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg, .pdf"
                          />
                          <label className="custom-file-label" htmlFor="Utility">
                            {formData.utilityBill
                              ? formData.utilityBill.name
                              : "Upload File"}
                          </label>
                        </li>
                      </ul>
                      {errors.utilityBill && (
                        <p className="error-msg">{errors.utilityBill}</p>
                      )}
                    </div>
                  </div> */}
                </form>
                <div className="btn-box flex space-between">
                  <Link
                    to="/personal-investment-profile"
                    className="cta-btn back-btn"
                  >
                    Back
                  </Link>
                  <Link onClick={handleSubmit} className="cta-btn blue-btn">
                    {loading ? (
                      <div style={{ marginLeft: "-30%" }}>
                        <Loaderbutton />{" "}
                      </div>
                    ) : (
                      "Next"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default InvestorSignupStep2;
