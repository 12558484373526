import React, { useEffect, useState } from "react";
import Header2 from "../../common/Header2";
import Header from "../../common/Header";
import Footer2 from "../../common/Footer2";
import { apiurl } from "../Url";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function ContactUs() {
  const [contactData, setcontactData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  const [contacterror, setContacterror] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  const [getContact, setGetContact] = useState("");

  const handleChangecontact = (e) => {
    const { name, value } = e.target;
    let filteredValue = value;
    if (name === "name") {
      filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    }
    if (name === "phone") {
      if (!/^[+\d\s]*$/.test(value)) {
        return;
      }
      if (filteredValue.length > 12) {
        setContacterror((previousError) => ({
          ...previousError,
          phone: ["Maximum 12 digits allowed"],
        }));
        return;
      }
    }
    setcontactData({
      ...contactData,
      [name]: filteredValue,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!contactData.name) {
      newErrors.name = "Name is required";
    }

    if (!contactData.email || !contactData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!pattern.test(contactData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (contactData.phone != "" && contactData.phone.trim().length < 8) {
      newErrors.phone = "Minimum 8 digits";
    }

    if (!contactData.message || !contactData.message.trim()) {
      newErrors.message = "Message is required";
    }

    setContacterror(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const contactus = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      
      try {
        axios
          .post(`${apiurl}visitor/contact`, {
            name: contactData.name,
            email: contactData.email,
            phone: contactData.phone,
            message: contactData.message,
          })
          .then((response) => {
            if(response.data.status){
              setcontactData((prevState) => ({
                ...prevState,
                name: "",
                email: "",
                message: "",
                phone: "",
              }));
              toast.success(response?.data?.message);
            }else{
              toast.error(response?.data?.message);
            }
       
          })
          .catch((error) => {
            console.error(error);
            setcontactData((prevState) => ({
              ...prevState,
              name: "",
              email: "",
              message: "",
              phone: "",
            }));
          });
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    } else {
      console.log(contacterror);
    }
  };
  const handleInputClickcontact = (name) => {
    setContacterror({ ...contacterror, [name]: "" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${apiurl}visitor/getContact`)
      .then((response) => {
        setGetContact(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Contact us</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="contact-us-breadcrum home-banner-sec">
        <div className="container">
          <div className="bredcrum-box flex align-center">
            <div className="left-box">
              <h5>Contact Us</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="location-sec">
        <div className="container">
          <div className="row flex">
            <div className="box">
              <div className="inner-text">
                <img src="src/images/location-tranparent.svg" alt="" />
                <h3>Address</h3>
                <address>
                  {/* Udharaa Headquarters 123 Innovation Drive, Tech City, State,
                  45678, Country */}
                  {getContact.address}
                </address>
              </div>
            </div>
            <div className="box color-blue">
              <img src="src/images/email-tranparent.svg" alt="" />
              <h3>Email</h3>
              <li className="genralenquiryli">
                <p className="generalinquiryp"> 
                  General Inquiries:{" "}
                  <a href="mailto:info@udharaa.com ">{getContact.email1}</a>
                </p>
                <p>
                  Support:{" "}
                  <a href="mailto:support@udharaa.com">{getContact.email2}</a>
                </p>
              </li>
            </div>
          </div>
        </div>
      </section>

      <section className="map-sec">
        <div className="container">
          <div className="row flex space-between">
            <div className="col-left">
              <h2>{getContact.title}</h2>
              <p dangerouslySetInnerHTML={{ __html: getContact.detail }}></p>
              <figure>
                <img src="src/images/contact-map-img.png" alt="" />
              </figure>
            </div>
            <div className="col-right">
              <div className="contact-box">
                <p className="contactusheading">Get in Touch and Let's Innovate Together!</p>
                <form action="">
                  <div className="form-group">
                    <label htmlFor="#">
                      Name<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="John Doe"
                      name="name"
                      className="form-control"
                      onChange={handleChangecontact}
                      value={contactData.name}
                      onClick={() => handleInputClickcontact("name")}
                    />
                    {contacterror.name && (
                      <p className="contact-error">{contacterror.name}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="#">
                      Email<span className="required-sign">*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="example@udharaa.com"
                      name="email"
                      className="form-control"
                      onChange={handleChangecontact}
                      value={contactData.email}
                      onClick={() => handleInputClickcontact("email")}
                    />
                    {contacterror.email && (
                      <p className="contact-error">{contacterror.email}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="#">Phone</label>
                    <input
                      type="number"
                      placeholder="(9999) 9999 999"
                      className="form-control"
                      name="phone"
                      onChange={handleChangecontact}
                      value={contactData.phone}
                      onClick={() => handleInputClickcontact("phone")}
                    />
                    {contacterror.phone && (
                      <p className="contact-error">{contacterror.phone}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="#">Message <span className="required-sign">*</span></label>
                    <textarea
                      placeholder="Enter Message here..."
                      id=""
                      className="form-control"
                      name="message"
                      onChange={handleChangecontact}
                      value={contactData.message}
                      onClick={() => handleInputClickcontact("message")}
                    ></textarea>
                    {contacterror.message && (
                      <p className="contact-error">{contacterror.message}</p>
                    )}
                  </div>
                  <div className="form-group contactusbutton">
                    <input
                      type="button"
                      value="Contact Us"
                      className="form-control cta-btn"
                      onClick={contactus}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default ContactUs;
