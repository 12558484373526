/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import Footer2 from "../../common/Footer2";
import Header2 from "../../common/Header2";
import Profile from "../../common/getProfile";
import { post } from "../../services/services";
import {
  getItem,
  handleFileChange,
  handlePhotoPdf,
  headers,
  isJsonString,
} from "../../common/reusable";
import { apiurl } from "../Url";
import { ImaageDocPDF } from "../../common/reusable";

function InvestorProfile() {
  const navigate = useNavigate();
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const [User, setUser] = useState({});
  const [documenttype, setDoucmenttype] = useState("");
  const [Popup, setPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [editType, setEditType] = useState("");
  const [editImage, setEditImage] = useState({ imageFile: null });
  const [historyData, sethistoryData] = useState([]);
  const [initialupdatePersonalData, setinitialUpdatePersonalData] = useState(
    {}
  );
  const [initialInvestmentinfo, setinitialInvestmentinfo] = useState({});
  const [updatePersonalData, setUpdatePersonalData] = useState({
    profilePicture: "",
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    occupation: "",
    investmentFocus: "",
    experience: "",
    about: "",
    linkedinURL: "",
    websiteURL: "",
    investmentFocusother: "",
  });
  const [updateInvestmentInfo, setUpdateInvestmentInfo] = useState({
    investmentSize: "",
    roleInInvestments: "",
    previousInvestments: "",
    riskProfile: "",
    expectations: "",
    decisionFactors: "",
    portfolioStrategy: "",
    geographicPreference: "",
    decisionFactorsother: "",
    portfolioStrategyother: "",
  });
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState();
  const userId = getItem("UserId");
  const [shouldFetchProfile, setShouldFetchProfile] = useState(true);
  const [investmentProfilePopup, setInvestmentProfilePopup] = useState(false);
  const [investmentInfoPopup, setInvestmentInfoPopup] = useState(false);

  const getHistoryData = () => {
    axios
      .get(`${apiurl}investors/viewInvestments/${userId}`)

      .then(response => {
        if (response?.data?.data) {
          sethistoryData(response?.data?.data);
        } else if (
          !response?.data?.data &&
          response?.data?.message === "Investment not found"
        ) {
          sethistoryData();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await Profile();
      if (profile) {
        setUser(profile);

        setUpdatePersonalData({
          name: profile?.user_id?.name,
          email: profile?.user_id?.email,
          profilePicture: profile?.profile_picture,
          phoneNumber: profile?.phone_number,
          address: profile?.address,
          occupation: profile?.occupation,
          investmentFocus: isJsonString(profile?.investment_focus)
            ? JSON.parse(profile?.investment_focus)
            : profile?.investment_focus,
          investmentFocusother: profile?.investment_focusother,
          experience: profile?.experience,
          about: profile?.bio,
          linkedinURL:
            profile?.linked_in_url != "undefined" ? profile?.linked_in_url : "",
          websiteURL:
            profile?.professional_website != "undefined"
              ? profile?.professional_website
              : "",
        });
        setUpdateInvestmentInfo({
          investmentSize: profile?.investment_size,
          roleInInvestments: profile?.role_in_investment,
          previousInvestments: profile?.previous_investment,
          riskProfile: profile?.risk_profile,
          expectations: profile?.expectations,
          decisionFactorsother: profile?.decision_factorsother,
          portfolioStrategyother: profile?.portfolio_strategyother,
          decisionFactors: isJsonString(profile?.decision_factors)
            ? JSON.parse(profile?.decision_factors)
            : profile?.decision_factors,
          portfolioStrategy: profile?.portfolio_strategy,
          geographicPreference: isJsonString(profile?.geographic_preference)
            ? JSON.parse(profile?.geographic_preference)
            : profile?.geographic_preference,
        });
      }
    };
    getHistoryData();
    fetchProfile();
    if (shouldFetchProfile) {
      fetchProfile();
      setShouldFetchProfile(false); // reset the flag
    }
  }, [shouldFetchProfile, editPopup]);
  const handleChange = e => {
    const { name, value, files, type, checked } = e.target;
    const phoneNumberPattern = /^[+\d\s\-\(\)\.\/]*$/;
    if (name === "phoneNumber") {
      if (!phoneNumberPattern.test(value)) {
        setErrors({ ...errors, [name]: "Alphabets not allowed" });
        return;
      }
    }
    if (files && files.length > 0) {
      const file = files[0];
      const maxSize = 5 * 1024 * 1024;

      if (file.size > maxSize) {
        toast.warn("File size should not exceed 5MB", {
          position: "top-center",
        });
      } else {
        setUpdatePersonalData({
          ...updatePersonalData,
          [name]: file,
        });
      }
    } else {
      if (type === "checkbox" && investmentInfoPopup) {
        setUpdateInvestmentInfo(prevFormData => {
          const currentData = prevFormData[name];
          const currentArray = Array.isArray(currentData)
            ? currentData
            : currentData
            ? [currentData]
            : [];
          const newValues = checked
            ? [...currentArray, value]
            : currentArray.filter(v => v !== value);

          return {
            ...prevFormData,
            [name]: newValues,
          };
        });
      } else {
        setUpdateInvestmentInfo({
          ...updateInvestmentInfo,
          [name]: value,
        });
      }
      if (type === "checkbox" && investmentProfilePopup) {
        setUpdatePersonalData(prevFormData => {
          const currentData = prevFormData[name];
          const currentArray = Array.isArray(currentData)
            ? currentData
            : currentData
            ? [currentData]
            : [];
          const newValues = checked
            ? [...currentArray, value]
            : currentArray.filter(v => v !== value);

          return {
            ...prevFormData,
            [name]: newValues,
          };
        });
      } else {
        setUpdatePersonalData({
          ...updatePersonalData,
          [name]: value,
        });
      }
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (!updatePersonalData.name) {
      newErrors.name = "Name is required.";
    }
    if (!updatePersonalData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required.";
    }
    if (updatePersonalData.phoneNumber.length < 6) {
      newErrors.phoneNumber = "Phone Number More then 6 Digit.";
    }
    if (!updatePersonalData.address) {
      newErrors.address = "Address is required.";
    }
    if (!updatePersonalData.occupation) {
      newErrors.occupation = "Occupation is required.";
    }
    if (!updatePersonalData.investmentFocus[0]) {
      newErrors.investmentFocus = "Investment Focus is required.";
    }
    if (
      !updatePersonalData.investmentFocusother &&
      updatePersonalData?.investmentFocus?.includes("Other")
    ) {
      newErrors.investmentFocusother = "Other Details are required.";
    }
    // if (!updatePersonalData.experience) {
    //   newErrors.experience = " Experience is required.";
    // }
    if (!updatePersonalData.about) {
      newErrors.about = "About is required.";
    }
    if (!updatePersonalData.profilePicture) {
      newErrors.profilePicture = "Profile Picture is required.";
    }
    if (!updatePersonalData.email) {
      newErrors.email = "Email is required.";
    }
    if (updatePersonalData.linkedinURL) {
      if (!/^https?:\/\/.*/i.test(updatePersonalData.linkedinURL)) {
        newErrors.linkedinURL =
          "Linkdein URL must start with https:// or http:// ";
      }
    }
    if (updatePersonalData.websiteURL) {
      if (!/^https?:\/\/.*/i.test(updatePersonalData.websiteURL)) {
        newErrors.websiteURL =
          "Personal URL must start with https:// or http:// ";
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const changedFields = [];
      Object.keys(updatePersonalData).forEach(key => {
        if (updatePersonalData[key] !== initialupdatePersonalData[key]) {
          changedFields.push(key);
        }
      });
      const toHumanReadable = (field) => {
        return field
          .replace(/([a-z])([A-Z])/g, '$1 $2') 
          .replace(/^./, str => str.toUpperCase()); 
      };
      
    
      const formatChangedFields = (changedFieldsString) => {
       
        const changedFieldsArray = changedFieldsString.split(',').map(field => field.trim());
        
       
        return changedFieldsArray
          .map(toHumanReadable)
          .join(", ");
      };
      const changedFieldsText = changedFields
        .map(field => `${field}`)
        .join(", ");
     const showtext=formatChangedFields(changedFieldsText)

      const formdata = new FormData();
      formdata.append("name", updatePersonalData.name);
      formdata.append("email", updatePersonalData.email);
      formdata.append("phone_number", updatePersonalData.phoneNumber);
      formdata.append("address", updatePersonalData.address);
      formdata.append("occupation", updatePersonalData.occupation);
      formdata.append(
        "investment_focus",
        JSON.stringify(updatePersonalData.investmentFocus)
      );
      formdata.append(
        "investment_focusother",
        updatePersonalData.investmentFocusother
      );
      formdata.append("bio", updatePersonalData.about);
      formdata.append("linked_in_url", updatePersonalData.linkedinURL);
      formdata.append("professional_website", updatePersonalData.websiteURL);
      formdata.append("profile_picture", updatePersonalData.profilePicture);
      formdata.append(
        "text",
        `${initialupdatePersonalData.name} has changed ${showtext} in his profile`
      );
      post(
        `investors/updatePersonalProfile/${Userid}?updateBy=user`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(response => {
          if (!response) {
          } else {
            if (!response.data.status) {
              toast.error(response.data.message);
            } else {
              toast.success("Profile Updated ");
              setShouldFetchProfile(true);
              setInvestmentProfilePopup(false);
            }
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  useEffect(() => {
    if (investmentProfilePopup) {
      setinitialUpdatePersonalData(updatePersonalData);
    } else {
    }
  }, [investmentProfilePopup]);

  useEffect(() => {
    if (investmentInfoPopup) {
      setinitialInvestmentinfo(updateInvestmentInfo);
    } else {
    }
  }, [investmentInfoPopup]);

  const investmentInfoSubmit = (e, fetchProfile) => {
    e.preventDefault();

    const newErrors = {};
    if (!updateInvestmentInfo.investmentSize) {
      newErrors.investmentSize = "Investment Size is required.";
    }
    if (!updateInvestmentInfo.roleInInvestments) {
      newErrors.roleInInvestments = "Role In Investments is required.";
    }
    if (!updateInvestmentInfo.previousInvestments) {
      newErrors.previousInvestments = "Previous Investments is required.";
    }
    if (!updateInvestmentInfo.riskProfile) {
      newErrors.riskProfile = "Risk Profile is required.";
    }
    if (!updateInvestmentInfo.expectations) {
      newErrors.expectations = "Expectations is required.";
    }
    if (
      !updateInvestmentInfo?.decisionFactors ||
      !updateInvestmentInfo?.decisionFactors[0]
    ) {
      newErrors.decisionFactors = "Decision Factors is required.";
    }
    if (
      !updateInvestmentInfo.decisionFactorsother &&
      updateInvestmentInfo.decisionFactors?.includes("Other")
    ) {
      newErrors.decisionFactorsother = "Please specify Other option.";
    }
    if (!updateInvestmentInfo.portfolioStrategy) {
      newErrors.portfolioStrategy = "Portfolio Strategy is required.";
    }
    if (
      updateInvestmentInfo.portfolioStrategy === "Other" &&
      !updateInvestmentInfo.portfolioStrategyother
    ) {
      newErrors.portfolioStrategyother = "Please specificy other option ";
    }
    if (
      !updateInvestmentInfo.geographicPreference ||
      !updateInvestmentInfo.geographicPreference[0]
    ) {
      newErrors.geographicPreference = "Geographic Preference is required.";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const changedFields = [];
      Object.keys(updateInvestmentInfo).forEach(key => {
        if (updateInvestmentInfo[key] !== initialInvestmentinfo[key]) {
          changedFields.push(key);
        }
      });
      const toHumanReadable = (field) => {
        return field
          .replace(/([a-z])([A-Z])/g, '$1 $2') 
          .replace(/^./, str => str.toUpperCase()); 
      };
      
    
      const formatChangedFields = (changedFieldsString) => {
       
        const changedFieldsArray = changedFieldsString.split(',').map(field => field.trim());
        
       
        return changedFieldsArray
          .map(toHumanReadable)
          .join(", ");
      };
      const changedFieldsText = changedFields
        .map(field => `${field}`)
        .join(", ");
     const showtext=formatChangedFields(changedFieldsText)
      const data = {
        investment_size: updateInvestmentInfo.investmentSize,
        role_in_investment: updateInvestmentInfo.roleInInvestments,
        previous_investment: updateInvestmentInfo.previousInvestments,
        risk_profile: updateInvestmentInfo.riskProfile,
        expectations: updateInvestmentInfo.expectations,
        decision_factors: JSON.stringify(updateInvestmentInfo.decisionFactors),
        decision_factorsother:
          updateInvestmentInfo.decisionFactorsother != null &&
          updateInvestmentInfo.decisionFactors?.includes("Other")
            ? updateInvestmentInfo.decisionFactorsother
            : "",
        portfolio_strategyother:
          updateInvestmentInfo.portfolioStrategy === "Other" &&
          updateInvestmentInfo.portfolioStrategyother != null
            ? updateInvestmentInfo.portfolioStrategyother
            : "",
        portfolio_strategy: updateInvestmentInfo.portfolioStrategy,
        geographic_preference: JSON.stringify(
          updateInvestmentInfo.geographicPreference
        ),
        text: `${updatePersonalData.name} has changed ${showtext} in his profile`,
      };

      post(
        `${apiurl}investors/updateInvestmentInfo/${Userid}?updateBy=user`,
        data
      )
        .then(response => {
          if (!response) {
          } else {
            if (!response.data.status) {
              toast.error(response.data.message);
            } else {
              toast.success("Profile Updated ");
              setShouldFetchProfile(true); // trigger re-fetching the profile
              setInvestmentInfoPopup(false);
            }
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };

  const UpdateImage = e => {
    e.preventDefault();
    let url = "";
    let key = "";
    if (editType == "passport") {
      url = "investors/chnagePassport";
      key = "passport";
    } else if (editType == "license") {
      url = "investors/chnageLicense";
      key = "drive_license";
    } else if (editType == "aadhar") {
      url = "investors/chnageAdharCard";
      key = "aadhar_card";
    } else if (editType == "bank") {
      url = "investors/changeBankStatement";
      key = "bank_statement";
    } else if (editType == "AC") {
      url = "investors/changeAccreditation";
      key = "accreditation";
    } else if (editType == "OC") {
      url = "investors/changeOwership";
      key = "ownership";
    } else if (editType == "IT") {
      url = "investors/changeIncomeTax";
      key = "income_tax_return";
    }
    let updatePersonalData = new FormData();
    updatePersonalData.append(key, editImage?.imageFile);
    try {
      axios
        .post(`${apiurl}${url}/${userId}`, updatePersonalData, headers())
        .then(res => {
          setEditPopup(false);
          setEditImage({ imageFile: null });
        })
        .catch(err => {
          setEditPopup(false);
          setEditImage({ imageFile: null });

          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const userType = JSON.parse(localStorage.getItem("userType"));
    if (userType != null) {
      if (userType != "Investor") {
        navigate("/");
      }
    }
  }, []);
  const getinvestmenthistory = (e, id) => {
    e.preventDefault();
    navigate("/investment-history", { state: { user_id: id } });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Investor profile</title>
      </Helmet>
      <div className="popup-wrapper-sec">
        {/* message to startup */}
        <div className="popup-wrapper" id="investor-profile">
          <div className="pop-cross-btn">
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
          <div className="inner-poupup">
            <div className="name-box-wrapper">
              <div className="row-box flex">
                <div className="name-box">
                  <h3>J</h3>
                </div>
                <div className="col-right">
                  <div className="stage-icon flex">
                    <img src="src/images/stage-icon.svg" alt="" />
                    <p>Stage of the business: Revenue-generating</p>
                  </div>
                  <div className="name-url">
                    <h3>
                      {User?.user_id?.name}
                      <a href="https://techgenius.com" target="_blank">
                        <img src="src/images/white-globe.svg" alt="" />{" "}
                        https://techgenius.com
                      </a>
                    </h3>
                  </div>
                  <div className="contact-btn-wrapper">
                    <ul>
                      <li className="flex">
                        <img src="src/images/mail-icon.svg" alt=""/>
                        <a href="mailto: john.doe@techgenius.com">
                          {" "}
                          john.doe@techgenius.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <form action="">
                <div className="form-group">
                  <label htmlFor="">Your Message</label>
                  <textarea
                    name=""
                    className="form-control"
                    placeholder="Enter your text here (maximum 1500 characters)"
                    id=""
                  ></textarea>
                </div>
              </form>
              <div className="interested flex">
                <a href="#" className="cta-btn">
                  I'm Interested
                </a>
                <a href="#" className="cta-btn">
                  Request for More Information
                </a>
                <div className="light-text">
                  <div className="sub-title">I'm Interested,</div>
                  <p>
                    Hi, I'm interested in learning more about your startup and
                    potential investment opportunities. Could we schedule a call
                    to discuss further?
                  </p>
                </div>
                <div className="light-text">
                  <p>
                    Hello, I'm intrigued by your startup and would like to
                    explore potential investment opportunities. Let's connect to
                    discuss this further.
                  </p>
                </div>
                <div className="light-text">
                  <p>
                    Hi, I'm currently short on time but very interested in your
                    startup. Could you send me more information so I can review
                    it at my convenience?
                  </p>
                </div>
              </div>
              <div className="btn-group flex">
                <a href="#" className="cta-btn">
                  Send
                </a>
                <a href="#" className="cta-btn border">
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
        {investmentProfilePopup && (
          <div className="popup-container-custom">
            <div className="popup-wrapper  business-verification pop-open">
              <div className="top-head-box flex space-between align-center">
                <h5>Update Personal and Investment Profile</h5>
                <div
                  className="pop-cross-btn"
                  onClick={() => {
                    setInvestmentProfilePopup(false);
                  }}
                >
                  <img src="src/images/cross-icon.svg" alt="" />
                </div>
              </div>
              <div className="inner-poupup inner-popup_2">
                <div className="custom-file ">
                  <ul className="custom-inputfile">
                    <li>
                      <h6>
                        Upload Photo<span className="required-sign">*</span>
                      </h6>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="profilePicture"
                        name="profilePicture"
                        onChange={handleChange}
                        accept=".svg, .png, .jpg, .jpeg, .pdf"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="profilePicture"
                      >
                        {updatePersonalData.profilePicture
                          ? updatePersonalData.profilePicture.length > 20
                            ? `${updatePersonalData.profilePicture.slice(
                                0,
                                20
                              )}...`
                            : updatePersonalData.profilePicture.name
                          : "Upload a professional profile picture"}
                      </label>
                    </li>
                  </ul>
                  {errors.profilePicture && (
                    <p className="error-msg">{errors.profilePicture}</p>
                  )}
                </div>
                <form action="">
                  <div className="form-wrapper flex space-between">
                    <div className="form-group half">
                      <label htmlFor="">
                        Name<span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="John Doe"
                        className="form-control"
                        onChange={handleChange}
                        name="name"
                        value={updatePersonalData.name}
                      />
                      {errors.name && (
                        <p className="error-msg">{errors.name}</p>
                      )}
                    </div>
                    <div className="form-group half">
                      <label htmlFor="">
                        Email<span className="required-sign">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder=" john.doe@techgenius.com"
                        className="form-control"
                        onChange={handleChange}
                        name="email"
                        value={updatePersonalData.email}
                      />
                      {errors.email && (
                        <p className="error-msg">{errors.email}</p>
                      )}
                    </div>
                    <div className="form-group half">
                      <label htmlFor="">
                        Phone Number<span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="+1 (123) 456-7890"
                        className="form-control"
                        onChange={handleChange}
                        name="phoneNumber"
                        value={updatePersonalData.phoneNumber}
                        maxLength="14"
                      />
                      {errors.phoneNumber && (
                        <p className="error-msg">{errors.phoneNumber}</p>
                      )}
                    </div>
                    <div className="form-group half">
                      <label htmlFor="">
                        Address<span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="San Francisco, CA"
                        className="form-control"
                        onChange={handleChange}
                        name="address"
                        value={updatePersonalData.address}
                      />
                      {errors.address && (
                        <p className="error-msg">{errors.address}</p>
                      )}
                    </div>
                    <div className="form-group half">
                      <label htmlFor="">
                        Occupation<span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Venture Capitalist"
                        className="form-control"
                        onChange={handleChange}
                        name="occupation"
                        value={updatePersonalData.occupation}
                      />
                      {errors.occupation && (
                        <p className="error-msg">{errors.occupation}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <h5>Investment Focus</h5>
                      <label htmlFor="">
                        What are your primary areas of investment interest?
                        <span className="required-sign">*</span>
                      </label>
                      <div className="custom-check-box">
                        <ul className="react-check-box">
                          <li>
                            <div className="checkbox">
                              <label className="flex">
                                <input
                                  type="checkbox"
                                  name="investmentFocus"
                                  value="Technology"
                                  checked={
                                    updatePersonalData?.investmentFocus?.includes(
                                      "Technology"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                />
                                <img src="src/images/technology.svg" alt="img" />
                                Technology
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="checkbox">
                              <label className="flex">
                                <input
                                  type="checkbox"
                                  name="investmentFocus"
                                  value="Healthcare"
                                  checked={
                                    updatePersonalData?.investmentFocus?.includes(
                                      "Healthcare"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                />
                                <img src="src/images/healthcare.svg" alt="img" />
                                Healthcare
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="checkbox">
                              <label className="flex">
                                <input
                                  type="checkbox"
                                  name="investmentFocus"
                                  value="Education"
                                  checked={
                                    updatePersonalData?.investmentFocus?.includes(
                                      "Education"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                />
                                <img src="src/images/education.svg" alt="img" />
                                Education
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="checkbox">
                              <label className="flex">
                                <input
                                  type="checkbox"
                                  name="investmentFocus"
                                  value="Energy"
                                  checked={
                                    updatePersonalData?.investmentFocus?.includes(
                                      "Energy"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                />
                                <img src="src/images/energy.svg" alt="img" />
                                Energy
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="checkbox">
                              <label className="flex">
                                <input
                                  type="checkbox"
                                  name="investmentFocus"
                                  value="Consumer Goods"
                                  checked={
                                    updatePersonalData?.investmentFocus?.includes(
                                      "Consumer Goods"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                />
                                <img src="src/images/consumer.svg" alt="img" />
                                Consumer Goods
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </li>
                          <li>
                            {/* {updatePersonalData?.investmentFocus} */}
                            <div className="checkbox">
                              <label className="flex">
                                <input
                                  type="checkbox"
                                  value="Other"
                                  name="investmentFocus"
                                  checked={
                                    updatePersonalData?.investmentFocus?.includes(
                                      "Other"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                />
                                <img src="src/images/other.svg" alt="img" />
                                <p>
                                  Other <small>(Please specify)</small>
                                </p>
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      {errors.investmentFocus && (
                        <p className="error-msg">{errors.investmentFocus}</p>
                      )}
                    </div>
                    {updatePersonalData?.investmentFocus?.includes("Other") && (
                      <div className="form-group">
                        <input
                          type="text"
                          name="investmentFocusother"
                          placeholder="Please specify"
                          className="form-control"
                          value={updatePersonalData.investmentFocusother}
                          onChange={handleChange}
                        />
                        {errors.investmentFocusother && (
                          <p className="error-msg">
                            {errors.investmentFocusother}
                          </p>
                        )}
                      </div>
                    )}

                    <div className="form-group half">
                      <label htmlFor="">
                        Experience<span className="required-sign">*</span>
                      </label>
                      <textarea
                        type="text"
                        placeholder="Veteran (Over 15 years of experience in startup investments, successfully funded over 50 startups, with 10 achieving significant exits)."
                        className="form-control"
                        onChange={handleChange}
                        name="experience"
                        value={updatePersonalData.experience}
                      />
                      {errors.experience && (
                        <p className="error-msg">{errors.experience}</p>
                      )}
                    </div>
                    <div className="form-group half">
                      <label htmlFor="">
                        About<span className="required-sign">*</span>
                      </label>
                      <textarea
                        type="text"
                        onChange={handleChange}
                        name="about"
                        value={updatePersonalData.about}
                        placeholder="John Doe is a seasoned venture capitalist with over 15 years of experience in the investment industry. He has a proven track record of identifying and nurturing high-potential"
                        className="form-control"
                      />
                      {errors.about && (
                        <p className="error-msg">{errors.about}</p>
                      )}
                    </div>
                    <div className="form-group half">
                      <label htmlFor="">LinkedIn URL (Optional)</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        name="linkedinURL"
                        value={updatePersonalData.linkedinURL}
                        placeholder="https://"
                      />
                      {errors.linkedinURL && (
                        <p className="error-msg">{errors.linkedinURL}</p>
                      )}
                      <p>
                        *Include the link to your LinkedIn profile for
                        networking and verification purposes.
                      </p>
                    </div>
                    <div className="form-group half">
                      <label htmlFor="">Personal Website URL (Optional)</label>
                      <input
                        type="text"
                        placeholder="https://"
                        className="form-control"
                        onChange={handleChange}
                        name="websiteURL"
                        value={updatePersonalData.websiteURL}
                      />
                      {errors.websiteURL && (
                        <p className="error-msg">{errors.websiteURL}</p>
                      )}
                      <p>
                        *If applicable, provide the URL to your personal or
                        professional website.
                      </p>
                    </div>
                  </div>
                  <div className="btn-box flex space-between">
                    <a
                      className="cta-btn cancel-btn"
                      onClick={() => {
                        setInvestmentProfilePopup(false);
                      }}
                    >
                      Cancel
                    </a>
                    <a
                      href="#"
                      className="cta-btn upate-btn"
                      onClick={handleSubmit}
                    >
                      Update
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {investmentInfoPopup && (
          <div className="popup-container-custom">
            <div
              className="popup-wrapper pop-open custom-popup-hight2"
              id="update-invest-profile"
            >
              <div className="top-head-box flex space-between align-center">
                <h5>Update Investment Info</h5>
                <div
                  className="pop-cross-btn"
                  onClick={() => {
                    setInvestmentInfoPopup(false);
                  }}
                >
                  <img src="src/images/cross-icon.svg" alt="" />
                </div>
              </div>
              <div className="inner-poupup">
                <form action="">
                  <div className="form-wrapper-box flex space-between">
                    <div className="form-group custom-radio-btn half">
                      <h5>
                        Investment Size<span className="required-sign">*</span>
                      </h5>
                      <p>
                        What range of investment are you typically comfortable
                        making?
                      </p>
                      <ul>
                        <li>
                          <label className="flex">
                            Less than ₹50,00,000
                            <input
                              type="radio"
                              name="investmentSize"
                              value="Less than ₹50,00,000"
                              checked={
                                updateInvestmentInfo.investmentSize ==
                                "Less than ₹50,00,000"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            ₹50,00,000 to ₹100,00,000
                            <input
                              type="radio"
                              name="investmentSize"
                              value="₹50,00,000 to ₹100,00,000"
                              checked={
                                updateInvestmentInfo.investmentSize ==
                                "₹50,00,000 to ₹100,00,000"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            ₹100,00,000 to ₹50,000,000
                            <input
                              type="radio"
                              name="investmentSize"
                              value="₹100,00,000 to ₹50,000,000"
                              checked={
                                updateInvestmentInfo.investmentSize ==
                                "₹100,00,000 to ₹50,000,000"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            More than ₹50,000,000
                            <input
                              type="radio"
                              name="investmentSize"
                              value="More than ₹50,000,000"
                              checked={
                                updateInvestmentInfo.investmentSize ==
                                "More than ₹50,000,000"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                      {errors.investmentSize && (
                        <p className="error-msg">{errors.investmentSize}</p>
                      )}
                    </div>
                    <div className="form-group custom-radio-btn half">
                      <h5>
                        Role in Investments
                        <span className="required-sign">*</span>
                      </h5>
                      <p>
                        Do you prefer a passive or active role in your
                        investments?
                      </p>
                      <ul>
                        <li>
                          <label className="flex">
                            Passive
                            <input
                              type="radio"
                              name="roleInInvestments"
                              value="Passive"
                              checked={
                                updateInvestmentInfo.roleInInvestments ==
                                "Passive"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            Active
                            <input
                              type="radio"
                              name="roleInInvestments"
                              value="Active"
                              checked={
                                updateInvestmentInfo.roleInInvestments ==
                                "Active"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                      {errors.roleInInvestments && (
                        <p className="error-msg">{errors.roleInInvestments}</p>
                      )}
                    </div>
                    <div className="form-group half">
                      <h5 className="custom-h-text">
                        Previous Investments
                        <span className="required-sign">*</span>
                      </h5>
                      <p className="custom-p-text">
                        Can you list some of your previous investments and their
                        outcomes?
                      </p>
                      <input
                        type="text"
                        name="previousInvestments"
                        className="form-control"
                        value={updateInvestmentInfo.previousInvestments}
                        onChange={handleChange}
                      />
                      {errors.previousInvestments && (
                        <p className="error-msg">
                          {errors.previousInvestments}
                        </p>
                      )}
                    </div>
                    <div className="form-group custom-radio-btn half">
                      <h5>
                        Risk Profile<span className="required-sign">*</span>
                      </h5>
                      <p>
                        What is your risk tolerance when investing in startups?
                      </p>
                      <ul>
                        <li>
                          <label className="flex">
                            Low
                            <input
                              type="radio"
                              name="riskProfile"
                              value="Low"
                              checked={
                                updateInvestmentInfo.riskProfile == "Low"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            Moderate
                            <input
                              type="radio"
                              name="riskProfile"
                              value="Moderate"
                              checked={
                                updateInvestmentInfo.riskProfile == "Moderate"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            High
                            <input
                              type="radio"
                              name="riskProfile"
                              value="High"
                              checked={
                                updateInvestmentInfo.riskProfile == "High"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                      {errors.riskProfile && (
                        <p className="error-msg">{errors.riskProfile}</p>
                      )}
                    </div>
                    <div className="form-group custom-radio-btn half">
                      <h5>
                        Expectations<span className="required-sign">*</span>
                      </h5>
                      <p>
                        What are your expectations in terms of return on
                        investment (ROI)?
                      </p>
                      <ul>
                        <li>
                          <label className="flex">
                            Below 10%
                            <input
                              type="radio"
                              name="expectations"
                              value="Below 10%"
                              checked={
                                updateInvestmentInfo.expectations == "Below 10%"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            10% to 20%
                            <input
                              type="radio"
                              name="expectations"
                              value="10% to 20%"
                              checked={
                                updateInvestmentInfo.expectations ==
                                "10% to 20%"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            20% to 50%
                            <input
                              type="radio"
                              value="20% to 50%"
                              name="expectations"
                              checked={
                                updateInvestmentInfo.expectations ==
                                "20% to 50%"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            More than 50%
                            <input
                              type="radio"
                              value="More than 50%"
                              name="expectations"
                              checked={
                                updateInvestmentInfo.expectations ==
                                "More than 50%"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                      {errors.expectations && (
                        <p className="error-msg">{errors.expectations}</p>
                      )}
                    </div>
                    <div className="form-group custom-radio-btn half">
                      <h5>
                        Decision Factors<span className="required-sign">*</span>
                      </h5>
                      <p>
                        What key factors influence your decision to invest in a
                        startup?
                      </p>
                      <ul>
                        <li>
                          <label className="flex">
                            Team
                            <input
                              type="checkbox"
                              value="Team"
                              name="decisionFactors"
                              checked={
                                updateInvestmentInfo?.decisionFactors?.includes(
                                  "Team"
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            Idea
                            <input
                              type="checkbox"
                              name="decisionFactors"
                              value="Idea"
                              checked={
                                updateInvestmentInfo?.decisionFactors?.includes(
                                  "Idea"
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            Market Size
                            <input
                              type="checkbox"
                              name="decisionFactors"
                              value="Market Size"
                              checked={
                                updateInvestmentInfo?.decisionFactors?.includes(
                                  "Market Size"
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            Scalability
                            <input
                              type="checkbox"
                              name="decisionFactors"
                              value="Scalability"
                              checked={
                                updateInvestmentInfo?.decisionFactors?.includes(
                                  "Scalability"
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            Other
                            <input
                              type="checkbox"
                              value="Other"
                              name="decisionFactors"
                              checked={
                                updateInvestmentInfo?.decisionFactors?.includes(
                                  "Other"
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                      {errors.decisionFactors && (
                        <p className="error-msg">{errors.decisionFactors}</p>
                      )}
                    </div>
                    {updateInvestmentInfo?.decisionFactors?.includes(
                      "Other"
                    ) && (
                      <div className="form-group half">
                        <input
                          type="text"
                          name="decisionFactorsother"
                          placeholder="Please specify"
                          className="form-control"
                          value={updateInvestmentInfo.decisionFactorsother}
                          onChange={handleChange}
                        />
                        {errors.decisionFactorsother && (
                          <p className="error-msg">
                            {errors.decisionFactorsother}
                          </p>
                        )}
                      </div>
                    )}
                    <div className="form-group custom-radio-btn half">
                      <h5>
                        Portfolio Strategy
                        <span className="required-sign">*</span>
                      </h5>
                      <p>
                        How does investing in startups fit into your overall
                        portfolio strategy?
                      </p>
                      <ul>
                        <li>
                          <label className="flex">
                            Main focus
                            <input
                              type="radio"
                              name="portfolioStrategy"
                              value="Main focus"
                              checked={
                                updateInvestmentInfo.portfolioStrategy ==
                                "Main focus"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            Diversification
                            <input
                              type="radio"
                              value="Diversification"
                              name="portfolioStrategy"
                              checked={
                                updateInvestmentInfo.portfolioStrategy ==
                                "Diversification"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            Speculative part of the portfolio
                            <input
                              type="radio"
                              name="portfolioStrategy"
                              value="Speculative part of the portfolio"
                              checked={
                                updateInvestmentInfo.portfolioStrategy ==
                                "Speculative part of the portfolio"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            Other
                            <input
                              type="radio"
                              value="Other"
                              name="portfolioStrategy"
                              checked={
                                updateInvestmentInfo.portfolioStrategy ==
                                "Other"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                      {errors.portfolioStrategy && (
                        <p className="error-msg">{errors.portfolioStrategy}</p>
                      )}
                    </div>
                    {updateInvestmentInfo.portfolioStrategy == "Other" && (
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Specify other option"
                          className="form-control"
                          name="portfolioStrategyother"
                          value={updateInvestmentInfo.portfolioStrategyother}
                          onChange={handleChange}
                        />

                        {errors.portfolioStrategyother && (
                          <p className="error-msg">
                            {errors.portfolioStrategyother}
                          </p>
                        )}
                      </div>
                    )}
                    <div className="form-group custom-radio-btn half">
                      <h5>
                        Geographic Preference
                        <span className="required-sign">*</span>
                      </h5>
                      <p>
                        Do you have a geographic preference for your
                        investments?
                      </p>
                      <ul>
                        <li>
                          <label className="flex">
                            Local
                            <input
                              type="checkbox"
                              name="geographicPreference"
                              value="Local"
                              checked={
                                updateInvestmentInfo?.geographicPreference?.includes(
                                  "Local"
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            National
                            <input
                              type="checkbox"
                              value="National"
                              name="geographicPreference"
                              checked={
                                updateInvestmentInfo?.geographicPreference?.includes(
                                  "National"
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            International
                            <input
                              type="checkbox"
                              name="geographicPreference"
                              value="International"
                              checked={
                                updateInvestmentInfo?.geographicPreference?.includes(
                                  "International"
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="flex">
                            No preference
                            <input
                              type="checkbox"
                              name="geographicPreference"
                              value="No preference"
                              checked={
                                updateInvestmentInfo?.geographicPreference?.includes(
                                  "No preference"
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                      {errors.geographicPreference && (
                        <p className="error-msg">
                          {errors.geographicPreference}
                        </p>
                      )}
                    </div>
                  </div>
                </form>
                <div className="form-group btngroup space-end full-width flex mt-20">
                  <button
                    className="cta-btn border"
                    onClick={() => {
                      setInvestmentInfoPopup(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="cta-btn" onClick={investmentInfoSubmit}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* additional questions ss*/}
        <div className="popup-wrapper" id="update-additional">
          <div className="top-head-box flex space-between align-center">
            <h5>Update Additional Questions</h5>
            <div className="pop-cross-btn">
              <img src="src/images/cross-icon.svg" alt="" />
            </div>
          </div>
          <div className="inner-poupup">
            <form action="">
              <div className="form-wrapper-box flex space-between">
                <div className="form-group">
                  <h5>Revenue Model</h5>
                  <label htmlFor="">
                    What is your business’s revenue model?
                  </label>
                  <div className="custom-check-box">
                    <ul>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/Subscription-based.svg" alt="img" />
                            Subscription-based
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/transaction-fee-based.svg" alt="img" />
                            Transaction fee-based
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/Freemium.svg" alt="img" />
                            Freemium
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/advertising.svg" alt="img" />
                            Advertising
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/direct-sales.svg" alt="img" />
                            Direct sales
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/other.svg" alt="img" />
                            <p>
                              Other <small>(Please specify)</small>
                            </p>
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form-group half">
                  <h5>Market Analysis</h5>
                  <label htmlFor="">
                    Who are your main competitors, and what is your competitive
                    advantage?
                  </label>
                  <input type="text" placeholder="" className="form-control" />
                </div>
                <div className="form-group half">
                  <h5>Team Background</h5>
                  <label htmlFor="">
                    Please provide information about the key members of your
                    team and their roles.
                  </label>
                  <input type="text" placeholder="" className="form-control" />
                </div>
                <div className="form-group custom-radio-btn half">
                  <h5>Previous Funding</h5>
                  <p>Have you received any previous investments?</p>
                  <ul>
                    <li>
                      <label className="flex">
                        Yes
                        <input type="radio" name="radio" checked="" />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="flex">
                        No
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="form-group full-width">
                  <label htmlFor="">
                    please specify who invested and what were the terms
                  </label>
                  <input
                    type="text"
                    placeholder="..."
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <h5>Business Goals</h5>
                  <label htmlFor="">
                    What are your short-term and long-term goals for the
                    business?
                  </label>
                  <input type="text" placeholder="" className="form-control" />
                </div>
                <div className="form-group">
                  <h5>Legal Structure</h5>
                  <p>What is the legal structure of your business?</p>
                  <div className="custom-check-box">
                    <ul>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/sole-proprietorship.svg" alt="img" />
                            Sole Proprietorship
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/partnership.svg" alt="img" />
                            Partnership
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/corporation.svg" alt="img" />
                            Corporation
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/llc.svg" alt="img" />
                            Limited Liability Company (LLC)
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <label className="flex">
                            <input type="checkbox" />
                            <img src="src/images/other.svg" alt="img" />
                            Other
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form-group custom-radio-btn half">
                  <h5>Support Needs</h5>
                  <p>Beyond funding, what other support are you looking for?</p>
                  <ul>
                    <li>
                      <label className="flex">
                        Mentorship
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="flex">
                        Network access
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="flex">
                        Strategic partnerships
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="flex">
                        Other
                        <input type="radio" name="radio" checked="" />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="form-group half">
                  <input type="text" placeholder="" className="form-control" />
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* edit popup*/}
        {editPopup && (
          <div class="popup-container-custom">
            <div
              className={editPopup ? "popup-wrapper pop-open" : "popup-wrapper"}
              id="document-update"
            >
              <div className="top-head-box flex space-between align-center">
                <h5>
                  Update Your{" "}
                  {documenttype === "1"
                    ? "Passport"
                    : documenttype === "2"
                    ? "Driver's License"
                    : documenttype === "3"
                    ? "Aadhaar card"
                    : documenttype === "4"
                    ? "Bank Statement"
                    : documenttype === "5"
                    ? "Latest Income Tax Returns"
                    : documenttype === "6"
                    ? "Ownership Certificate"
                    : documenttype === "7"
                    ? "Accerdition Certificate"
                    : ""}
                </h5>
                <div
                  onClick={() => {
                    setEditPopup(false);
                    setImage("");
                    setEditImage({ imageFile: null });
                    setEditType("");
                  }}
                  className="pop-cross-btn"
                >
                  <img src="src/images/cross-icon.svg" alt="" />
                </div>
              </div>
              <div className="inner-poupup">
                <div className="dotted-box">
                  {image && <ImaageDocPDF src={image} />}
                  {editImage &&
                    editImage?.imageFile &&
                    (editImage?.imageFile?.type == "image/jpeg" ||
                    editImage?.imageFile?.type == "image/jpg" ||
                    editImage?.imageFile?.type == "image/png" ? (
                      <img alt="img"
                        src={URL.createObjectURL(editImage?.imageFile)}
                      ></img>
                    ) : (
                      <iframe title="iframe" src={URL.createObjectURL(editImage?.imageFile)} />
                    ))}
                  <div className="icon flex">
                    <div className="edit-pen">
                      <img src="src/images/edit-pen-img.svg" alt="" />
                      <input
                        onChange={e => {
                          if (
                            editType == "passport" ||
                            editType == "aadhar" ||
                            editType == "license"
                          ) {
                            handlePhotoPdf(e, setEditImage);
                          } else {
                            handleFileChange(e, setEditImage);
                          }
                        }}
                        name="imageFile"
                        className="fileImage"
                        type="file"
                      />
                    </div>
                    {/* <div className="delete-pen">
                  <img src="src/images/delete-pen-img.svg" alt="" />
                </div> */}
                  </div>
                </div>
                <div className="form-group btngroup space-end full-width flex mt-20">
                  <button
                    onClick={() => {
                      setEditPopup(false);
                      setImage("");
                      setEditType("");
                      setEditImage({ imageFile: null });
                    }}
                    className="cta-btn border"
                  >
                    Cancel
                  </button>
                  <button onClick={e => UpdateImage(e)} className="cta-btn">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {Popup && (
          <div class="popup-container-custom">
            <div
              className={Popup ? "popup-wrapper pop-open" : "popup-wrapper"}
              id="document-view"
            >
              <div className="top-head-box flex space-between align-center">
                <h5>
                  Your{" "}
                  {documenttype === "1"
                    ? "Passport"
                    : documenttype === "2"
                    ? "Driver's License"
                    : documenttype === "3"
                    ? "Aadhaar card"
                    : documenttype === "4"
                    ? "Bank Statement"
                    : documenttype === "5"
                    ? "Latest Income Tax Returns"
                    : documenttype === "6"
                    ? "Ownership Certificate"
                    : documenttype === "7"
                    ? "Accerdition Certificate"
                    : ""}
                </h5>
                <div onClick={() => setPopup(false)} className="pop-cross-btn">
                  <img src="src/images/cross-icon.svg" alt="" />
                </div>
              </div>
              <div className="inner-poupup">
                <ImaageDocPDF src={image} />
              </div>
            </div>
          </div>
        )}
      </div>
      <section className="profile-Sec dashboard-sec my_profile">
        <div className="container">
          <div className="bredcrum-box flex align-center">
            <div className="left-box">
              <h5>My Profile</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>My Profile</li>
              </ul>
            </div>
          </div>
          <div className="row flex space-between">
            <div className="big-text-box">
              <div className="shadow-box edit-box">
                <div className="name-box-wrapper">
                  <div className="row-box flex">
                    <div className="name-box user-photo">
                      <img alt="img" src={User?.profile_picture}></img>
                    </div>
                    <div className="col-right">
                      {/* <div className="stage-icon flex">
                        <p>Business Registration No# 989F745F</p>
                      </div> */}
                      <div className="name-url">
                        <h3>{User?.user_id?.name}</h3>
                      </div>
                      <div className="contact-btn-wrapper">
                        <ul className="flex">
                          <li className="flex">
                            <img src="src/images/mail-icon.svg" alt="" className="iconimage"/>
                            <a href={`mailto:${User?.user_id?.email}`}>
                              {" "}
                              {User?.user_id?.email}
                            </a>
                          </li>
                          <li className="flex">
                            <img
                              src="src/images/call-icon.svg"
                              alt="call-icon"
                              className="iconimage"
                            />
                            <a href={`tel:${User?.phone_number}`}>
                              {User?.phone_number}
                            </a>
                          </li>
                          <li className="flex">
                            <img src="src/images/location-blue.svg" alt="" className="iconimage" />
                            <p>{User?.address}</p>
                          </li>
                          <li className="flex">
                            <img
                              src="src/images/venture-capitalist-icon.svg"
                              alt=""
                              className="iconimage"
                            />
                            <p>{User?.occupation}</p>
                          </li>
                        </ul>
                      </div>
                      <div className="invester-focus flex">
                        <h6>Investment Focus:</h6>
                        {User?.investment_focus?.includes("Technology") && (
                          <div className="dotted-box flex">
                            <img src="src/images/technology.svg" alt="" />
                            <p>Technology</p>
                          </div>
                        )}
                        {User?.investment_focus?.includes("Healthcare") && (
                          <div className="dotted-box flex">
                            <img src="src/images/healthcare.svg" alt="" />
                            <p>Healthcare</p>
                          </div>
                        )}
                        {User?.investment_focus?.includes("Education") && (
                          <div className="dotted-box flex">
                            <img src="src/images/education.svg" alt="" />
                            <p>Education</p>
                          </div>
                        )}
                        {User?.investment_focus?.includes("Consumer Goods") && (
                          <div className="dotted-box flex custom-img-padding">
                            <img src="src/images/consumer-goods.svg" alt="" />
                            <p>Consumer Goods</p>
                          </div>
                        )}
                        {User?.investment_focus?.includes("Energy") && (
                          <div className="dotted-box flex">
                            <img alt="img" src="src/images/energy.svg" />
                            <p>Energy</p>
                          </div>
                        )}
                        {User?.investment_focus?.includes("Other") && (
                          <div className="dotted-box flex">
                            <img alt="img" src="src/images/other.svg" />
                            <p>{User?.investment_focusother}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="full-detail-box">
                    {/* <h6>Experience</h6>
                    <p>
                     {User?.experience}
                    </p> */}
                    <h6>About</h6>
                    <p>{User?.bio}</p>
                    <div className="social-icon mt-24">
                      <ul className="flex">
                        <li>Social Links:</li>
                        <li className="flex align-center">
                          {User?.professional_website &&
                            User?.professional_website != "null" &&
                            User?.professional_website != "undefined" && (
                              <a
                                href={User?.professional_website}
                                target="_blank"
                              >
                                <img
                                  src="src/images/globe.svg"
                                  alt="socialicon"
                                />
                              </a>
                            )}
                          {User?.linked_in_url &&
                            User?.linked_in_url != "null" &&
                            User?.linked_in_url != "undefined" && (
                              <a href={User?.linked_in_url} target="_blank">
                                <img
                                  src="src/images/Linkedin1.png"
                                  alt="socialicon"
                                />
                              </a>
                            )}
                          {!User?.professional_website &&
                            !User?.professional_website != "null" &&
                            !User?.professional_website != "undefined" &&
                            !User?.linked_in_url &&
                            !User?.linked_in_url != "null" &&
                            !User?.linked_in_url != "undefined" && (
                              <p>No Social media links added</p>
                            )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="edit-btn"
                  onClick={() => {
                    setInvestmentProfilePopup(true);
                  }}
                >
                  <img src="src/images/edit-pen.svg" alt="" />
                </div>
              </div>
              <div className="doucment-box-wrapper">
                <div className="h4">Identity Verification Documents</div>
                <div className="document-box-row flex space-between">
                  {/* {User?.passport != null && ( */}
                  <div className="document-col shadow-box">
                    {User?.passport == "" || User?.passport == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setImage(User?.passport);
                              setEditType("passport");
                              setDoucmenttype("1");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>Passport</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>Passport</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setImage(User?.passport);
                                  setPopup(true);
                                  setEditType("passport");
                                  setDoucmenttype("1");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                              {/* <a
                            onClick={() => {
                              setEditPopup(true);
                              setImage(User?.passport);
                              setEditType("passport");
                            }}
                            className="open-document-popup"
                          >
                            <img src="src/images/white-editpen.svg" alt="" />
                          </a> */}
                            </div>
                          </li>
                        </ul>
                        {User?.passport && (
                          <ImaageDocPDF src={User?.passport} alt="" />
                        )}
                      </>
                    )}
                  </div>
                  {/* )} */}
                  {/* {User?.drive_license != null && ( */}
                  <div className="document-col shadow-box">
                    {User?.drive_license == "" ||
                    User?.drive_license == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setImage(User?.drive_license);
                              setEditType("license");
                              setDoucmenttype("2");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>Driver’s License</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>Driver’s License</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setImage(User?.drive_license);
                                  setPopup(true);
                                  setEditType("license");
                                  setDoucmenttype("2");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                              {/* <a
                              onClick={() => {
                                setEditPopup(true);
                                setEditType("license");
                                setImage(User?.drive_license);
                              }}
                              className="open-document-popup"
                            >
                              <img src="src/images/white-editpen.svg" alt="" />
                            </a> */}
                            </div>
                          </li>
                        </ul>
                        {User?.drive_license && (
                          <ImaageDocPDF src={User?.drive_license} alt="" />
                        )}
                      </>
                    )}
                  </div>
                  {/* )} */}
                  {/* {User?.aadhar_card != null && ( */}
                  <div className="document-col shadow-box full-width">
                    {User?.aadhar_card == "" || User?.aadhar_card == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setImage(User?.aadhar_card);
                              setEditType("aadhar");
                              setDoucmenttype("3");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>Aadhaar Card</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>Aadhaar Card</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setImage(User?.aadhar_card);
                                  setPopup(true);
                                  setEditType("aadhar");
                                  setDoucmenttype("3");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                              {/* <a
                              onClick={() => {
                                setEditPopup(true);
                                setEditType("aadhar");
                                setImage(User?.aadhar_card);
                              }}
                              className="open-document-popup"
                            >
                              <img src="src/images/white-editpen.svg" alt="" />
                            </a> */}
                            </div>
                          </li>
                        </ul>
                        {User?.aadhar_card && (
                          <ImaageDocPDF src={User?.aadhar_card} alt="" />
                        )}
                      </>
                    )}
                  </div>
                  {/* )} */}
                </div>
              </div>
              <div className="doucment-box-wrapper">
                <div className="h4">Financial Standing and Accreditation</div>
                <div className="document-box-row flex space-between">
                  {/* {User?.bank_statement != null && ( */}
                  <div className="document-col shadow-box">
                    <ul className="flex space-between">
                    
                      {User?.bank_statement == "" ||
                    User?.bank_statement == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setEditType("bank");
                              setImage(User?.bank_statement);
                              setDoucmenttype("4");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>Bank Statements</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>Bank Statements</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setEditPopup(true);
                                  setEditType("bank");
                                  setImage(User?.bank_statement);
                                  setDoucmenttype("4");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                          
                            </div>
                          </li>
                        </ul>
                     
                        {User?.bank_statement && (
                          <ImaageDocPDF src={User?.bank_statement} />
                        )}
                      </>
                    )}
                    </ul>
                    {User?.bank_statement && (
                      <ImaageDocPDF src={User?.bank_statement} />
                    )}
                  </div>
                  {/* )} */}
                  {/* {User?.income_tax_return != null && ( */}
                  <div className="document-col shadow-box">
                    {User?.income_tax_return == "" ||
                    User?.income_tax_return == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setEditType("IT");
                              setImage(User?.income_tax_return);
                              setDoucmenttype("5");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>Latest income tax returns</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>Latest income tax returns</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setEditPopup(true);
                                  setEditType("IT");
                                  setImage(User?.income_tax_return);
                                  setDoucmenttype("5");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                              {/* <a
                                  onClick={() => {
                                    setEditPopup(true);
                                    setEditType("IT");
                                    setImage(User?.income_tax_return);
                                  }}
                                  className="open-document-popup"
                                >
                                  <img
                                    src="src/images/white-editpen.svg"
                                    alt=""
                                  />
                                </a> */}
                            </div>
                          </li>
                        </ul>
                        {/* <img src={User?.income_tax_return} alt="" /> */}
                        {User?.income_tax_return && (
                          <ImaageDocPDF src={User?.income_tax_return} />
                        )}
                      </>
                    )}
                  </div>
                  {/* // )} */}
                  {/* {User?.proof_of_assets != null && ( */}
                  <div className="document-col shadow-box">
                    {User?.proof_of_assets == "" ||
                    User?.proof_of_assets == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setEditType("OC");
                              setImage(User?.proof_of_assets);
                              setDoucmenttype("6");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>Ownership Certificates</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>Ownership Certificates</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setImage(User?.proof_of_assets);
                                  setPopup(true);
                                  setEditType("OC");
                                  setDoucmenttype("6");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                              {/* <a
                                  onClick={() => {
                                    setEditPopup(true);
                                    setEditType("OC");
                                    setImage(User?.proof_of_assets);
                                  }}
                                  className="open-document-popup"
                                >
                                  <img
                                    src="src/images/white-editpen.svg"
                                    alt=""
                                  />
                                </a> */}
                            </div>
                          </li>
                        </ul>
                        {User?.proof_of_assets && (
                          <ImaageDocPDF src={User?.proof_of_assets} />
                        )}
                      </>
                    )}
                  </div>
                  {/* // )} */}
                  {/* {User?.accreditation_certificate != null && ( */}
                  <div className="document-col shadow-box">
                    {User?.accreditation_certificate == "" ||
                    User?.accreditation_certificate == null ? (
                      <>
                        <div className="plus-img">
                          <img
                            onClick={() => {
                              setEditPopup(true);
                              setEditType("AC");
                              setImage(User?.accreditation_certificate);
                              setDoucmenttype("7");
                            }}
                            src="src/images/plus.png"
                            alt=""
                          />
                        </div>
                        <p>Accredition Certificates</p>
                      </>
                    ) : (
                      <>
                        <ul className="flex space-between">
                          <li>Accredition Certificates</li>
                          <li>
                            <div className="icon-box flex">
                              <a
                                onClick={() => {
                                  setImage(User?.accreditation_certificate);
                                  setPopup(true);
                                  setEditType("AC");
                                  setDoucmenttype("7");
                                }}
                                className="view-document-popupbtn"
                              >
                                <img src="src/images/black-eye.svg" alt="" />
                              </a>
                              {/* <a
                              onClick={() => {
                                setEditPopup(true);
                                setEditType("AC");
                                setImage(User?.accreditation_certificate);
                              }}
                              className="open-document-popup"
                            >
                              <img
                                src="src/images/white-editpen.svg"
                                alt=""
                              />
                            </a> */}
                            </div>
                          </li>
                        </ul>
                        {User?.accreditation_certificate && (
                          <ImaageDocPDF src={User?.accreditation_certificate} />
                        )}
                      </>
                    )}
                  </div>
                  {/* // )} */}
                </div>
              </div>
            </div>
            <div className="small-text-box">
              <div className="shadow-box">
                {/* <div className="shadowedit"> */}
                <div
                  className="edit-btn"
                  onClick={() => {
                    setInvestmentInfoPopup(true);
                  }}
                >
                  <img src="src/images/edit-pen.svg" alt="" />
                </div>
                {/* </div> */}
                <h5>Investment Info</h5>
                <ul className="dotted-line">
                  <li>
                    <p>Investment Size</p>
                    <span>{User?.investment_size}</span>
                  </li>
                  <li>
                    <p>Role in Investments</p>
                    <span>{User?.role_in_investment}</span>
                  </li>
                  <li>
                    <p>Previous Investments</p>
                    <span>₹ {User?.previous_investment}</span>
                  </li>
                  <li>
                    <p>Risk Profile</p>
                    <span>{User?.risk_profile}</span>
                  </li>
                  <li>
                    <p>Expectations</p>
                    <span>{User?.expectations}</span>
                  </li>
                  <li>
                    <p>Decision Factors</p>
                    <span>
                      {isJsonString(User?.decision_factors)
                        ? (() => {
                            const factors = JSON.parse(User.decision_factors);
                            const filteredFactors = factors.filter(
                              factor => factor !== "Other"
                            );
                            return filteredFactors.length > 0
                              ? filteredFactors.join(", ") +
                                  " , " +
                                  User?.decision_factorsother
                              : "No factors available";
                          })()
                        : User?.decision_factors}
                    </span>
                  </li>
                  <li>
                    <p>Portfolio Strategy</p>

                    <span>
                      {User?.portfolio_strategy === "Other"
                        ? User?.portfolio_strategyother
                        : User?.portfolio_strategy}
                    </span>
                  </li>
                  <li>
                    <p>Geographic Preference</p>

                    {/* {User?.geographic_preference!="undefined"&&    <span>
                      {isJsonString(User?.geographic_preference)
                        ? JSON.parse(User?.geographic_preference)[1]
                          ? JSON.parse(User?.geographic_preference).join(", ")
                          : JSON.parse(User?.geographic_preference)
                        : User?.geographic_preference}
                    </span>} */}
                    <span>
                      {(() => {
                        const geoPreference = User?.geographic_preference;

                        if (isJsonString(geoPreference)) {
                          try {
                            const parsedPreference = JSON.parse(geoPreference);

                            if (Array.isArray(parsedPreference)) {
                              return parsedPreference.length > 1
                                ? parsedPreference.join(", ")
                                : parsedPreference[0];
                            } else {
                              return parsedPreference;
                            }
                          } catch (error) {
                            return geoPreference;
                          }
                        } else {
                          return geoPreference;
                        }
                      })()}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="shadow-box">
                <h5>Investment Level</h5>
                <div className="level align-center flex">
                  {User?.user_id?.level === "1" ? (
                    <>
                      <img src="src/images/Level-img.svg" alt="" /> Level{" "}
                      {User?.user_id?.level}
                    </>
                  ) : User?.user_id?.level === "2" ? (
                    <>
                      <img src="/src/Level2.png" alt="" /> Level{" "}
                      {User?.user_id?.level}
                    </>
                  ) : User?.user_id?.level === "3" ? (
                    <>
                      <img src="/src/Level3.png" alt="" /> Level{" "}
                      {User?.user_id?.level}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="shadow-box">
                <h5>Recent Investment</h5>
                <table>
                  {historyData != undefined && historyData.length != 0 ? (
                    <>
                      <thead>
                        <tr>
                          <th>Company Name</th>
                          <th>Investment Raise</th>
                          <th>Equity stake</th>
                        </tr>
                      </thead>
                    </>
                  ) : (
                    ""
                  )}

                  {historyData != undefined && historyData.length != 0 ? (
                    <>
                      {historyData.map((item, index) => (
                        <tbody>
                          <tr key={index}>
                            <td className="tableheadinghorizontal">
                              {item?.companyName}
                            </td>
                            <td>₹{item?.raise}</td>
                            <td>{item?.equity}%</td>
                          </tr>
                        </tbody>
                      ))}
                    </>
                  ) : (
                    <div className="nonvestment-text">
                      {" "}
                      <span>No Investment</span>
                    </div>
                  )}
                </table>
                {historyData != undefined && historyData.length != 0 ? (
                  <>
                    <Link
                      to="/investor-history"
                      onClick={e => {
                        e.preventDefault();
                        getinvestmenthistory(e, User._id);
                      }}
                      className="view-more"
                    >
                      View All
                    </Link>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default InvestorProfile;
