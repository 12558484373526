import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { post } from "../../services/services";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const handleChange = e => {
    const { value } = e.target;
    setEmail(value);
    setErrors(value);
  };
  const handleSubmit = e => {
    e.preventDefault();
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        post(`${apiurl}users/forgotPassword`, { email: email })
          .then(res => {
            if (!res) {
            } else {
              if (
                !res?.data?.status &&
                res?.data?.message === "User not found"
              ) {
                toast.error(res?.data?.message);
              } else {
                toast.success(res?.data?.message);
              }
            }
          })
          .catch(err => console.log(err));
      } catch (err) {
        console.error(err);
      }
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Forget password</title>
      </Helmet>
      <section className="create-account-sec forget-pass-sec">
        <div className="container">
          <div className="row flex">
            <div className="form-box w-100 m-auto">
              <div className="form-wrapper">
                <div className="top-comman-text text-center">
                  <Link to="/">
                    <img src="src/images/site-logo.svg" alt="" />
                  </Link>
                  <h4>Forget Password</h4>
                </div>
                <form action="">
                  <div className="form-group">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      placeholder="john.doe@udharaa.com"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="error-msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-group">
                      <button onClick={handleSubmit} className="cta-btn">Continue</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default ForgetPassword;
