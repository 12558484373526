import { useState, useEffect } from "react";
import { GetCountries } from "react-country-state-city";

const CountryList = () => {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    GetCountries().then(result => {
      setCountries(result);
    });
  }, []);
  return countries;
};

export default CountryList;
