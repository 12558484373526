import React from "react";

const Loader = () => {
  return (
    <div id="outer">
      <div id="middle">
        <div id="innerloader"></div>
      </div>
    </div>
  );
};

export default Loader;
