export const home = "/";
export const signup = "/signup";
export const signupstep2 = "/startup-Signup";
export const signupstep3 = "/identity-business-verfication1";
export const signupstep4 = "/identity-business-verfication2";
export const signupstep5 = "/additional-questions";
export const signupstep6 = "/unit-economics-business-growth";
export const signupstep7 = "/legal-compliance-declarations";
export const signupstep8 = "/about-business1";
export const signupstep9 = "/about-business2";
export const welcome = "/welcome";
export const investorsignup = "/investor-signup";
export const investorsignupstep1 = "/personal-investment-profile";
export const investorsignupstep2 = "/identity-verification";
export const investorsignupstep3 = "/financial-standing-accreditation";
export const investorsignupstep4 = "/additional-information";
export const welcomeinvestor = "/welcome-investor";
export const investordashboard = "/investor-dashboard";
export const login = "/login";
export const forgetpassword = "/forget-password";
export const passwordchange = "/change-Password";
export const passwordchangescucess = "/password-change-success";
export const investorviewdetails = "/investor-view-details";
export const copyinvestorviewdetails = "/investor-view-details";
export const investorprivacy = "/privacy-contract";
export const investorprivacyview = "/privacy-contract-view";
export const startupprofile = "/startup-profile";
// export const startupdashboard = "/startup-dashboard";
export const watchlist = "/favourite-list";
export const accountsetting = "/account-setting";
export const investorprofile = "/investor-profile";
export const terms = "/terms&conditions";
export const privacypolicy = "/privacy-policy";
export const support = "/support";
export const investmenthistory = "/investment-history";
export const aboutus = "/about-us";
export const contactus = "/contact-us";
export const messenger = "/messenger";
export const InvestorRoutes = [
  investordashboard,
  investorprivacy,
  investorprofile,
  investmenthistory,
  watchlist,
  investorviewdetails,
];
