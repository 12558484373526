/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

import ChatSidebar from "./ChatSidebar/ChatSidebar";
import Header2 from "../../common/Header2";
import Chat from "./Chat/Chat";
import { ChatContext } from "../../Context/ChatContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./CustomMessenger.css";

const CustomMessenger = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [draft, setDraft] = useState([]);

  const { state } = location;

  const { userChats, setFirstTimeSelectedChatId, contractSigned } =
    useContext(ChatContext);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (contractSigned) {
      // navigate("/messenger");
    } else {
      toast.error("Please sign your contract to view the chats");
      navigate("/privacy-contract");
    }
  }, []);

  useEffect(() => {
    setFirstTimeSelectedChatId(state?.id);
  }, [state?.id]);

  const [selectedChatId, setSelectedChatId] = useState(
    state?.chatId ? state?.chatId : null
  );

  useEffect(() => {
    if (state?.id) {
      let id = userChats?.filter(
        chat => chat?.members?.filter(a => a?._id == state?.id)?.length > 0
      );

      if (id && id?.length > 0) {
        setSelectedChatId(id[0]?._id);
      }
    }
  }, [state?.id]);

  return (
    <>
      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Messenger</title>
      </Helmet>
      <div className="app-container chatbox-wrapper">
        {width > 768 || (width <= 768 && selectedChatId == null) ? (
          <ChatSidebar
            chats={userChats?.sort((c, a) => {
              const cDate = new Date(c?.lastMessage?.createdAt);
              const aDate = new Date(a?.lastMessage?.createdAt);
              return aDate - cDate;
            })}
            onChatSelect={e => setSelectedChatId(e)}
          />
        ) : null}
        {width > 768 || (width <= 768 && selectedChatId != null) ? (
          <Chat
            onChatSelect={e => setSelectedChatId(e)}
            draft={draft?.filter(a => a?.selectedChatId == selectedChatId)[0]}
            setDraft={e => {
              let index;
              let d = [...draft];
              for (let i = 0; i < draft?.length; i++) {
                const e1 = draft[i];
                if (e1["selectedChatId"] == selectedChatId) {
                  index = i;
                  break;
                }
              }
              if (index != undefined) {
                d[index] = { selectedChatId, ...e };
                setDraft([...d]);
              } else {
                setDraft([...d, { selectedChatId, ...e }]);
              }
            }}
            _messages={
              userChats?.filter(chat => chat._id == selectedChatId)[0]?.messages
            }
            selectedChat={
              userChats
                ?.filter(chat => chat._id === selectedChatId)[0]
                ?.members?.filter(
                  a =>
                    a?._id !=
                    localStorage?.getItem("UserId")?.replaceAll('"', "")
                )[0]
            }
            startupID={
              userChats?.filter(chat => chat._id === selectedChatId)[0]
                ?.startupId
            }
            investorID={
              userChats?.filter(chat => chat._id === selectedChatId)[0]
                ?.investorId
            }
            chatId={selectedChatId}
            receiverId={
              userChats
                ?.filter(chat => chat._id == selectedChatId)[0]
                ?.members?.filter(
                  a =>
                    a?._id !=
                    localStorage?.getItem("UserId")?.replaceAll('"', "")
                )[0]?._id
            }
            isPin={
              userChats
                ?.filter(chat => chat._id == selectedChatId)[0]
                ?.pinChat?.filter(
                  a => a == localStorage?.getItem("UserId")?.replaceAll('"', "")
                )?.length > 0
                ? true
                : false
            }
            isBlockedByYou={
              userChats
                ?.filter(chat => chat._id == selectedChatId)[0]
                ?.blockedBy?.filter(
                  a => a == localStorage?.getItem("UserId")?.replaceAll('"', "")
                )?.length > 0
                ? true
                : false
            }
            areUBlocked={
              userChats
                ?.filter(chat => chat._id == selectedChatId)[0]
                ?.blockedBy?.filter(
                  a =>
                    a ==
                    userChats
                      ?.filter(chat => chat._id == selectedChatId)[0]
                      ?.members?.filter(
                        a =>
                          a?._id !=
                          localStorage?.getItem("UserId")?.replaceAll('"', "")
                      )[0]?._id
                )?.length > 0
                ? true
                : false
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default CustomMessenger;
