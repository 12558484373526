import "./App.css";
import {
  signup,
  home,
  signupstep2,
  signupstep3,
  signupstep4,
  signupstep5,
  signupstep6,
  signupstep7,
  signupstep8,
  signupstep9,
  welcome,
  investorsignup,
  investorsignupstep1,
  investorsignupstep2,
  investorsignupstep3,
  investorsignupstep4,
  welcomeinvestor,
  investordashboard,
  login,
  forgetpassword,
  passwordchange,
  passwordchangescucess,
  investorviewdetails,
  investorprivacy,
  startupprofile,
  startupdashboard,
  watchlist,
  accountsetting,
  investorprofile,
  terms,
  privacypolicy,
  support,
  investmenthistory,
  aboutus,
  contactus,
  messenger,
  investorprivacyview,
} from "./Routes/Routes";
import Home from "./maincomponent/Home";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from "./maincomponent/Signup/Signup";
import SignupStep2 from "./maincomponent/Signup/Signupstep2";
import SignupStep3 from "./maincomponent/Signup/Signupstep3";
import SignupStep4 from "./maincomponent/Signup/Signupstep4";
import SignupStep6 from "./maincomponent/Signup/Signupstep6";
import SignupStep5 from "./maincomponent/Signup/Signupstep5";
import SignupStep7 from "./maincomponent/Signup/Signupstep7";
import SignupStep8 from "./maincomponent/Signup/Signupstep8";
import SignupStep9 from "./maincomponent/Signup/Signupstep9";
import Welcome from "./maincomponent/Welcome/Welcome";
import InvestorSignup from "./maincomponent/InvestorSignup/InvestorSignup";
import InvestorSignupStep1 from "./maincomponent/InvestorSignup/InvestorSignupstep1";
import InvestorSignupStep2 from "./maincomponent/InvestorSignup/InvestorSignupstep2";
import WelcomeInvestor from "./maincomponent/Welcome/WelcomeInvestor";
import InvestorSignupStep3 from "./maincomponent/InvestorSignup/InvestorSignupstep3";
import InvestorSignupStep4 from "./maincomponent/InvestorSignup/InvestorSignupstep4";
import InvestorDashboard from "./maincomponent/InvestorDashboard/InvestorDashboard";
import Login from "./maincomponent/Login/Login";
import ForgetPassword from "./maincomponent/Login/ForgetPassword";
import PasswordChange from "./maincomponent/Login/PasswordChange";
import PasswordChangeScucess from "./maincomponent/Login/PasswordChangeScucess";
import InvestorViewDetails from "./maincomponent/InvestorDashboard/InvestorViewDetails";
import InvestorPrivacy from "./maincomponent/InvestorDashboard/InvestorPrivacy";
import StartupProfile from "./maincomponent/StartupDashboard/StartupProfile";
import StartupDashboard from "./maincomponent/StartupDashboard/StartupDashboard";
import Watchlist from "./maincomponent/InvestorDashboard/Watchlist";
import AccountSetting from "./maincomponent/InvestorDashboard/AccountSetting";
import Terms from "./maincomponent/terms&privacy/terms";
import Privacy from "./maincomponent/terms&privacy/privacy";
import NotFound from "./common/404";
import PrivateRoutes from "./Routes/PrivateRoutes";
import InvestorProfile from "./maincomponent/InvestorDashboard/InvestorProfile";
import Support from "./maincomponent/terms&privacy/Support";
import InvestmentHistory from "./maincomponent/InvestorDashboard/InvestmentHistory";
import AboutUs from "./maincomponent/terms&privacy/AboutUs";
import ContactUs from "./maincomponent/terms&privacy/ContactUs";
import CustomMessenger from "./Components/CustomMessenger/CustomMessenger";
import InvestorPrivacydownload from "./maincomponent/InvestorDashboard/InvestorPrivaydownload";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* Public Routes  */}

          <Route path={home} element={<Home />}></Route>
          <Route path={signup} element={<Signup />}></Route>
          <Route exact path="/linkedin" element={<LinkedInCallback />} />
          <Route path={signupstep2} element={<SignupStep2 />}></Route>
          <Route path={signupstep3} element={<SignupStep3 />}></Route>
          <Route path={signupstep4} element={<SignupStep4 />}></Route>
          <Route path={signupstep5} element={<SignupStep5 />}></Route>
          <Route path={signupstep6} element={<SignupStep6 />}></Route>
          <Route path={signupstep7} element={<SignupStep7 />}></Route>
          <Route path={signupstep8} element={<SignupStep8 />}></Route>
          <Route path={signupstep9} element={<SignupStep9 />}></Route>
          <Route path={investorsignup} element={<InvestorSignup />}></Route>
          <Route
            path={investorsignupstep1}
            element={<InvestorSignupStep1 />}
          ></Route>
          <Route
            path={investorsignupstep2}
            element={<InvestorSignupStep2 />}
          ></Route>
          <Route
            path={investorsignupstep3}
            element={<InvestorSignupStep3 />}
          ></Route>
          <Route
            path={investorsignupstep4}
            element={<InvestorSignupStep4 />}
          ></Route>
          <Route path={login} element={<Login />}></Route>
          <Route path={forgetpassword} element={<ForgetPassword />}></Route>
          <Route path={passwordchange} element={<PasswordChange />}></Route>
          <Route path={terms} element={<Terms />}></Route>
          <Route path={privacypolicy} element={<Privacy />}></Route>
          <Route path="*" element={<NotFound />}></Route>
          <Route path={support} element={<Support />}></Route>
          <Route path={aboutus} element={<AboutUs />}></Route>
          <Route path={contactus} element={<ContactUs />}></Route>
          <Route
              path={passwordchangescucess}
              element={<PasswordChangeScucess />}
            ></Route>
          {/* Private Routes  */}

          <Route element={<PrivateRoutes />}>
            <Route path={welcome} element={<Welcome />}></Route>
            <Route path={messenger} element={<CustomMessenger />}></Route>
            <Route path={welcomeinvestor} element={<WelcomeInvestor />}></Route>
            <Route
              path={investordashboard}
              element={<InvestorDashboard />}
            ></Route>
            <Route
              path={passwordchangescucess}
              element={<PasswordChangeScucess />}
            ></Route>
            <Route
              path={investorviewdetails}
              element={<InvestorViewDetails />}
            ></Route>
            <Route path={investorprivacy} element={<InvestorPrivacy />}></Route>
            <Route path={investorprivacyview} element={<InvestorPrivacydownload />}></Route>
            <Route path={startupprofile} element={<StartupProfile />}></Route>
            {/* <Route
              path={startupdashboard}
              element={<StartupDashboard />}
            ></Route> */}
            <Route path={watchlist} element={<Watchlist />}></Route>
            <Route path={accountsetting} element={<AccountSetting />}></Route>
            <Route path={investorprofile} element={<InvestorProfile />}></Route>
            <Route
              path={investmenthistory}
              element={<InvestmentHistory />}
            ></Route>
          </Route>
        </Routes>
        <ToastContainer
          position="top-center"
          style={{fontSize:"20px"}}
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </Router>
    </>
  );
}

export default App;
