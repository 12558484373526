/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState,ref, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ChatContext } from "../Context/ChatContext";
import { apiurl } from "../maincomponent/Url";
import {
  accountsetting,
  home,
  investordashboard,
  investorprofile,
  startupprofile,
  watchlist,
} from "../Routes/Routes";
import { get } from "../services/services";
import Profile from "./getProfile";
import Notifications from "./Notifications";
import { getItem, removeItem,setItem } from "./reusable";

function Header2({ change }) {
  const [menuActive, setMenuActive] = useState(false);
  const [User, setUser] = useState({});
  const [notifactionOpen, setNotifactionOpen] = useState(false);
  const navigate = useNavigate();
  const [modalclose, setModalclose] = useState(true);
  const [openhumberburg,setOpenhumberburg]=useState(false)
  const userType = getItem("userType");
  const { contractSigned, unreadCounts, socket } = useContext(ChatContext);
  const [profiledisapproved,setProfiledisapproved]=useState(false);

  const menuToggle = () => {
    setMenuActive(!menuActive);
  };
const userloginref=useRef()
  const notifactionOpenFunction = () => {
    if (notifactionOpen) {
      get(`${apiurl}users/updateUserStatus/${getItem("UserId")}`).then(
        response => {
          setModalclose(false);
        }
      );
    }
    setModalclose(true);
    document.body.classList.remove('scroll-stop');
    
    document.querySelector('header .toggle-btn').classList.remove('active');
    document.documentElement.classList.remove('scroll-stop');
    const nav = document.querySelector('header nav');
    if (nav) {
        nav.classList.remove('active');
    }
    setNotifactionOpen(!notifactionOpen);
  };

  useEffect(() => {
    if (socket == null) return;
    socket.on("new_user_notification", () => {
      getAllUserNotification();
    });

    return () => {
      socket?.off("new_user_notification");
    };
  }, [socket]);

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await Profile();
      if (profile) {
        setUser(profile); 
      }
    };
    if (getItem("UserId")) {
      fetchProfile();
    }
  }, [change]);

  useEffect(() => {
    const handleClickOutside = event => {
      const menu = document.getElementById("menu");
      if (menu && !menu.contains(event.target) && userloginref.current && !userloginref.current.contains(event.target)) {
        setMenuActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuActive]);



  const contractviewpage = e => {
    e.preventDefault();
    if (contractSigned) {
      navigate("/privacy-contract-view");
    } else {
      toast.error("Please sign your contract to view the chats");
      navigate("/privacy-contract");
    }
  };

  const [notifactiondata, Setnotificationdata] = useState();
  const getAllUserNotification = () => {
    get(
      `${apiurl}users/getAllUserNotification/${getItem(
        "UserId"
      )}?page=1&limit=10`
    )
      .then(response => {
        Setnotificationdata(response?.data?.list);
        if(getItem('notificationrejectprofile')===''&&getItem('notificationrejectprofile')!="true"){
          setItem('notificationrejectprofile',"true")
        }
      
        if(response?.data?.list[0].notificationType==="Profile Rejected" &&getItem('notificationrejectprofile')==="true"){
          setProfiledisapproved(true);
     
         }else if(response?.data?.list[0].notificationType!="Profile Rejected"){
     setProfiledisapproved(false);
          setItem('notificationrejectprofile',"true")
         }

      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    if (getItem("UserId")) {
      getAllUserNotification();
    }
  }, []);

  useEffect(() => {
    if (!modalclose) {
      getAllUserNotification();
      setModalclose(true);
    }
  }, [modalclose]);

  const openHumberburgtoggle=()=>{
    if(!openhumberburg){
      document.body.classList.toggle('scroll-stop');
    
      document.querySelector('header .toggle-btn').classList.add('active');
      document.documentElement.classList.toggle('scroll-stop');
      const nav = document.querySelector('header nav');
      if (nav) {
          nav.classList.add('active');
      }
    }else{
      document.body.classList.remove('scroll-stop');
    
      document.querySelector('header .toggle-btn').classList.remove('active');
      document.documentElement.classList.remove('scroll-stop');
      const nav = document.querySelector('header nav');
      if (nav) {
          nav.classList.remove('active');
      }
    }
 
    setOpenhumberburg(!openhumberburg)
  }


  return (
    <>
      {
      profiledisapproved && 
      <div className="popup-container-custom">
      <div
        className="popup-wrapper pop-open notificationreject"
        id=""
      >
        <div className="top-head-box flex space-between">
          <h5>Your Profile has been disapproved</h5>
          <div
            className="pop-cross-btn"
           onClick={(e)=>{setProfiledisapproved(false)
            setItem('notificationrejectprofile',"false")
           }}
          >
            <img src="src/images/cross-icon.svg" alt="" />
          </div>
        </div>
        <div className="inner-poupup">
      <p>{notifactiondata &&notifactiondata[0].message}</p>
        </div>
      </div>
    </div>
    }
      <div className={`menu ${menuActive ? "active" : ""}`} id="menu">
        <h6 className="menu-name">
          {User?.user_id?.name || User?.name}
          <br />
          <span>{User?.user_id?.email || User?.email}</span>
        </h6>
        <ul className="menu-options">
          <li>
            <Link
              to={userType != "StartUp" ? investorprofile : startupprofile}
              className="menu-text"
            >
              My profile
            </Link>
          </li>
          <li style={{ display: userType != "StartUp" ? "" : "none" }}>
            <Link to={investordashboard} className="menu-text">
              Business List
            </Link>
          </li>
          <li style={{ display: userType != "StartUp" ? "" : "none" }}>
            <Link to={watchlist} className="menu-text">
              My Favourite List
            </Link>
          </li>
          <li>
            <a onClick={e => contractviewpage(e)} className="menu-text">
              My Contract
            </a>
          </li>
          <li>
            <Link to={accountsetting} className="menu-text">
              Account settings
            </Link>
          </li>

          <li>
            <a
              onClick={e => {
                socket?.disconnect()
                e.preventDefault();
                removeItem("token");
                removeItem("userType");
                removeItem("UserId");
                removeItem("url");
                navigate("/login");
              }}
              href={home}
              className="menu-text"
            >
              Sign out
            </a>
          </li>
        </ul>
      </div>{" "}
      {notifactionOpen && (
        <Notifications
          notifactionOpenFunction={notifactionOpenFunction}
          contractSigned={contractSigned}
          notificationdata={notifactiondata}
        />
      )}
      <header className="site-header inner-header flex" style={{alignItems: 'center', height:'11vh'}}>
        <div className="container">
          <div className="nav-box flex space-between">
            <div className="site-branding">
              <Link to="/">
                <img src="src/images/site-logo.svg" alt="site-logo" />
              </Link>
            </div>
            <ul className="flex align-center normalnav">     <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/support">Support</a>
                </li>
                {/* <li>
                  <a href="/Contact-us">Contact us</a>
                </li> */}
                </ul>
       
            <div className="toggle-btn"  style={{right:getItem("userType")!=""&&!openhumberburg?"170px":"68px"}}onClick={openHumberburgtoggle}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <nav style={{right:openhumberburg? "0":''}}>
       
         
              <ul className="flex align-center custom-header">
                      <div className="navlogo">
              <Link to="/">
						<img src="src/images/site-logo.svg" alt=""/>
					</Link>
              </div>
                          <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a href="/support">Support</a>
                </li>
                {/* <li>
                  <a href="/">Services</a>
                </li> */}
              <li>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (contractSigned) {
                        navigate("/messenger");
                      } else {
                        toast.error(
                          "Please sign your contract to view the chats"
                        );
                        navigate("/privacy-contract");
                      }
                    }}
                  >
                    {unreadCounts ? (
                    <>Message <span className="required-sign">*</span></>
                    ) : (
                      <>Message</>
                   
                    )}
                  </a>
                </li>
                <li>
                  <a
                    className="open_notifaction"
                    onClick={notifactionOpenFunction}
                  >
                    {notifactiondata &&
                    notifactiondata?.filter(notification => !notification.seen)
                      .length != 0 ? (<>Noticification <span className="required-sign">*</span></>) :(<>Notification</>)}
                  </a>
                </li>
                {/* <li>
                  <a href="/Contact-us">Contact us</a>
                </li> */}
                {/* <li>
                  <a href="/">Help Center</a>
                </li> */}
               
              </ul>
            </nav>

            <div className="after-login" ref={userloginref}>
              <ul className="flex align-center">
         
                <li>
                  <a
                    className="open_notifaction"
                    onClick={notifactionOpenFunction}
                  >
                    {notifactiondata &&
                    notifactiondata?.filter(notification => !notification.seen)
                      .length != 0 ? (
                      <img src="src/images/notifaction.svg" alt="" className="headericomimage" />
                    ) : (
                      <img src="src/images/notifaction1.svg" alt="" className="headericomimage" />
                    )}
                  </a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (contractSigned) {
                        navigate("/messenger");
                      } else {
                        toast.error(
                          "Please sign your contract to view the chats"
                        );
                        navigate("/privacy-contract");
                      }
                    }}
                  >
                    {unreadCounts ? (
                      <img src="src/images/top-chat1.svg" alt="" className="headericomimage"  />
                    ) : (
                      <img src="src/images/top-chat.svg" alt="" className="headericomimage"  />
                    )}
                  </a>
                </li>
                <li className="user flex">
                  {userType === "StartUp" ? (
                    <img
                      className="profile"
                      src={User?.founderPhoto}
                      alt=""
                      onClick={menuToggle}
                    />
                  ) : (
                    <img
                      className="profile"
                      src={User?.profile_picture}
                      alt=""
                      onClick={menuToggle}
                    />
                  )}
                  {/* <select name="" id="" className="select-user">
                    <option value="john Doe">john Doe</option>
                    <option value="john Doe">john Doe</option>
                    <option value="john Doe">john Doe</option>
                  </select> */}
                </li>
                <Link className="user-name" onClick={menuToggle}>
                  {User?.user_id?.name || User?.name}
                </Link>
                <img
                  src="src/images/ChevronDown.png"
                  className="usernameicon headericomimage"
                  alt=""
                  onClick={menuToggle}
                />
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header2;
