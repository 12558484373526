import React from "react";
import { terms } from "../Routes/Routes";
function Footer3() {
  return (
    <>
      <footer className="inner-footer">
        <div className="container">
          <div className="inner-footer-content">
            <div className="secure-box">
              <ul className="flex align-center">
                <li>
                  <img src="src/images/secure-img.png" alt="secure" />
                </li>
                <li>
                  <p>
                    Our website is secured with SSL encryption to protect your
                    personal and financial information. Look for the SSL
                    certificate badge in the footer to ensure that your data is
                    safe.
                  </p>
                </li>
              </ul>
            </div>
            <div className="copy-right footer-line">
              <div className="flex align-center space-between">
                <p className="p-color">© 2024 Udharaa, Inc. All rights reserved.</p>
                <p>
                  <a className="footer_a"href={terms}>Terms and Policy</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer3;
