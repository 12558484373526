/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { post, get } from "../../services/services";
import  { setItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";

function SignupStep6() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    year1Revenue: "",
    year2Revenue: "",
    year3Revenue: "",
    year1GrossProfitMargin: "",
    year2GrossProfitMargin: "",
    year3GrossProfitMargin: "",
    year1EBIDTAMargin: "",
    year2EBIDTAMargin: "",
    year3EBIDTAMargin: "",
    CAC: "",
    LTV: "",
    burnRate: "",
    activeUsers: "",
    fundingSought: "",
  });

  const [errors, setErrors] = useState({});
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const handleChange = e => {
    const { name, value } = e.target;
    const patternnumber = /^[+\d\s\-\(\)\.\/]*$/;
    if (
      name === "year1Revenue" ||
      name === "year2Revenue" ||
      name === "year3Revenue" ||
      name === "year1GrossProfitMargin" ||
      name === "year2GrossProfitMargin" ||
      name === "year3GrossProfitMargin" ||
      name === "year1EBIDTAMargin" ||
      name === "year2EBIDTAMargin" ||
      name === "year3EBIDTAMargin"
    ) {
      if (!patternnumber.test(value)) {
        setErrors({ ...errors, [name]: "Alphabets not allowed" });
        return;
      }
    }
    if (name==="year1GrossProfitMargin"||name==="year2GrossProfitMargin"||name==="year3GrossProfitMargin"||name==="year1EBIDTAMargin"||name==="year2EBIDTAMargin"||name==="year3EBIDTAMargin") {
     if(value<0 ||value>100){
        setErrors({ ...errors, [name]: "Value should lie between 0 to 100" });
        return;
      }else{
        setErrors({ ...errors, [name]: "" });
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.year1Revenue) {
      newErrors.year1Revenue = "Year 1 Revenue is required.";
    }
    if (!formData.year2Revenue) {
      newErrors.year2Revenue = "Year 2 Revenue is required.";
    }
    if (!formData.year3Revenue) {
      newErrors.year3Revenue = "Year 3 Revenue is required.";
    }
    if (!formData.year1GrossProfitMargin) {
      newErrors.year1GrossProfitMargin =
        "Year 1 Gross Profit Margin is required.";
    }else if(parseFloat(formData.year1GrossProfitMargin)<0||parseFloat(formData.year1GrossProfitMargin)>100){
      newErrors.year1GrossProfitMargin =
      "Year 1 Gross Profit Margin value should be 0 to 100";
    }
    if (!formData.year2GrossProfitMargin) {
      newErrors.year2GrossProfitMargin =
        "Year 2 Gross Profit Margin is required.";
    }else if(parseFloat(formData.year2GrossProfitMargin)<0||parseFloat(formData.year2GrossProfitMargin)>100){
      newErrors.year1GrossProfitMargin =
      "Year 2 Gross Profit Margin value should be 0 to 100";
    }
    if (!formData.year3GrossProfitMargin) {
      newErrors.year3GrossProfitMargin =
        "Year 3 Gross Profit Margin is required.";
    }else if(parseFloat(formData.year3GrossProfitMargin)<0||parseFloat(formData.year3GrossProfitMargin)>100){
      newErrors.year2GrossProfitMargin =
      "Year 3 Gross Profit Margin value should be 0 to 100";
    }
    if (!formData.year1EBIDTAMargin) {
      newErrors.year3EBIDTAMargin = "Year 1 EBIDTA Margin is required.";
    }else if(parseFloat(formData.year1EBIDTAMargin)<0||parseFloat(formData.year1EBIDTAMargin>100)){
      newErrors.year1EBIDTAMargin =
      "Year 1 EBIDTA Margint value should be 0 to 100";
    }
    if (!formData.year2EBIDTAMargin) {
      newErrors.year2EBIDTAMargin = "Year 2 EBIDTA Margin is required.";
    }else if(parseFloat(formData.year2EBIDTAMargin)<0||parseFloat(formData.year2EBIDTAMargin>100)){
      newErrors.year2EBIDTAMargin =
      "Year 2 EBIDTA Margint value should be 0 to 100";
    }
    if (!formData.year3EBIDTAMargin) {
      newErrors.year3EBIDTAMargin = "Year 3 EBIDTA Margin is required.";
    }else if(parseFloat(formData.year3EBIDTAMargin)<0||parseFloat(formData.year3EBIDTAMargin>100)){
      newErrors.year3EBIDTAMargin =
      "Year 3 EBIDTA Margint value should be 0 to 100";
    }
    if (!formData.CAC) {
      newErrors.CAC = "Customer Acquisition Cost (CAC) is required.";
    }
    if (!formData.LTV) {
      newErrors.LTV = "Lifetime Value of a Customer (LTV) is required.";
    }
    if (!formData.burnRate) {
      newErrors.burnRate = "Burn Rate is required.";
    }
    if (!formData.activeUsers) {
      newErrors.activeUsers = "Number of Active Users/Clients is required.";
    }
    if (!formData.fundingSought) {
      newErrors.fundingSought = "Funding Sought is required.";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const data = {
        annual_revenue_year1: formData.year1Revenue,
        annual_revenue_year2: formData.year2Revenue,
        annual_revenue_year3: formData.year3Revenue,
        gross_profit_margin_year1: formData.year1GrossProfitMargin,
        gross_profit_margin_year2: formData.year2GrossProfitMargin,
        gross_profit_margin_year3: formData.year3GrossProfitMargin,
        ebidta_marginyear1: formData.year1EBIDTAMargin,
        ebidta_marginyear2: formData.year2EBIDTAMargin,
        ebidta_marginyear3: formData.year3EBIDTAMargin,
        cac: formData.CAC,
        liv: formData.LTV,
        burn_rate: formData.burnRate,
        funding_sougth: formData.fundingSought,
        noOfActiceUser: formData.activeUsers,
      };

      post(`${apiurl}startups/businessGrowth/${Userid}`, data)
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setLoading(false);
            } else {
              setItem("url", "/legal-compliance-declarations");
              navigate("/legal-compliance-declarations");
              setLoading(false);
            }
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}startups/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          const startupData = response?.data?.startups;
          setFormData({
            year1Revenue: startupData?.annual_revenue_year1,
            year2Revenue: startupData?.annual_revenue_year2,
            year3Revenue: startupData?.annual_revenue_year3,
            year1GrossProfitMargin: startupData?.gross_profit_margin_year1,
            year2GrossProfitMargin: startupData?.gross_profit_margin_year2,
            year3GrossProfitMargin: startupData?.gross_profit_margin_year3,
            year1EBIDTAMargin: startupData?.ebidta_marginyear1,
            year2EBIDTAMargin: startupData?.ebidta_marginyear2,
            year3EBIDTAMargin: startupData?.ebidta_marginyear3,
            CAC: startupData?.cac,
            LTV: startupData?.liv,
            burnRate: startupData?.burn_rate,
            fundingSought: startupData?.funding_sougth,
            activeUsers: startupData?.noOfActiceUser,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Unit economics business growth</title>
      </Helmet>
      <section className="signUp-Sec">
        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex completed">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Identity and Business Verification
                  </h4>
                  <p>Verify Your Details</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4 className="blurred-text">Additional Questions</h4>
                  <p> Enhance Your Profile</p>
                </div>
              </div>
              <div className="step-box flex active">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4>Unit Economics and Business Growth</h4>
                  <p>Showcase Your Potential</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4>Legal Compliance and Declarations</h4>
                  <p>Ensure Regulatory Compliance</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">5</div>
                <div className="text-box">
                  <h4>About Business</h4>
                  <p>Additional Info For Better Startup Information</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">6</div>
                <div className="text-box">
                  <h4>Completed</h4>
                  <p>Complete Your Registration Process</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">
                  Unit Economics and Business Growth
                </h5>
                <form action="">
                  <h5>Annual Revenue for the Past 3 Years</h5>
                  <div className="form-group">
                    <label htmlFor="">
                      Year 1<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Current year projection"
                      className="form-control"
                      name="year1Revenue"
                      value={formData.year1Revenue}
                      onChange={handleChange}
                    />
                    {errors.year1Revenue && (
                      <p className="error-msg">{errors.year1Revenue}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Year 2<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Last Year"
                      className="form-control"
                      name="year2Revenue"
                      value={formData.year2Revenue}
                      onChange={handleChange}
                    />
                    {errors.year2Revenue && (
                      <p className="error-msg">{errors.year2Revenue}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Year 3<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Year Before"
                      className="form-control"
                      name="year3Revenue"
                      value={formData.year3Revenue}
                      onChange={handleChange}
                    />
                    {errors.year3Revenue && (
                      <p className="error-msg">{errors.year3Revenue}</p>
                    )}
                  </div>
                  <h5>
                    Gross Profit Margins for the Past 3 Years{" "}
                    <strong>(in %)</strong>
                  </h5>
                  <div className="form-group">
                    <label htmlFor="">
                      Year 1<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Current year projection"
                      className="form-control"
                      name="year1GrossProfitMargin"
                      value={formData.year1GrossProfitMargin}
                      onChange={handleChange}
                    />
                    {errors.year1GrossProfitMargin && (
                      <p className="error-msg">
                        {errors.year1GrossProfitMargin}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Year 2<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Last Year"
                      className="form-control"
                      name="year2GrossProfitMargin"
                      value={formData.year2GrossProfitMargin}
                      onChange={handleChange}
                    />
                    {errors.year2GrossProfitMargin && (
                      <p className="error-msg">
                        {errors.year2GrossProfitMargin}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Year 3<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Year Before"
                      className="form-control"
                      name="year3GrossProfitMargin"
                      value={formData.year3GrossProfitMargin}
                      onChange={handleChange}
                    />
                    {errors.year3GrossProfitMargin && (
                      <p className="error-msg">
                        {errors.year3GrossProfitMargin}
                      </p>
                    )}
                  </div>
                  <h5>
                    EBIDTA margin for the past 3 years <strong>(in %)</strong>
                  </h5>
                  <div className="form-group">
                    <label htmlFor="">
                      Year 1<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Current year projection"
                      className="form-control"
                      name="year1EBIDTAMargin"
                      value={formData.year1EBIDTAMargin}
                      onChange={handleChange}
                    />
                    {errors.year1EBIDTAMargin && (
                      <p className="error-msg">{errors.year1EBIDTAMargin}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Year 2<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Last Year"
                      className="form-control"
                      name="year2EBIDTAMargin"
                      value={formData.year2EBIDTAMargin}
                      onChange={handleChange}
                    />
                    {errors.year2EBIDTAMargin && (
                      <p className="error-msg">{errors.year2EBIDTAMargin}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Year 3<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Year Before"
                      className="form-control"
                      name="year3EBIDTAMargin"
                      value={formData.year3EBIDTAMargin}
                      onChange={handleChange}
                    />
                    {errors.year3EBIDTAMargin && (
                      <p className="error-msg">{errors.year3EBIDTAMargin}</p>
                    )}
                  </div>
                  <div className="form-group bordertop">
                    <label htmlFor="">
                      10.Customer Acquisition Cost (CAC)
                      <span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="CAC"
                      className="form-control"
                      name="CAC"
                      value={formData.CAC}
                      onChange={handleChange}
                    />
                    {errors.CAC && <p className="error-msg">{errors.CAC}</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Lifetime Value of a Customer (LTV)
                      <span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="LTV"
                      className="form-control"
                      name="LTV"
                      value={formData.LTV}
                      onChange={handleChange}
                    />
                    {errors.LTV && <p className="error-msg">{errors.LTV}</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Burn Rate<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Burn Rate"
                      className="form-control"
                      name="burnRate"
                      value={formData.burnRate}
                      onChange={handleChange}
                    />
                    {errors.burnRate && (
                      <p className="error-msg">{errors.burnRate}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Number of Active Users/Clients
                      <span className="required-sign">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="0"
                      className="form-control"
                      name="activeUsers"
                      value={formData.activeUsers}
                      onChange={handleChange}
                    />
                    {errors.activeUsers && (
                      <p className="error-msg">{errors.activeUsers}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Funding Sought<span className="required-sign">*</span>
                    </label>
                    <textarea
                      type="text"
                      placeholder="I.e We'll allocate 25 lakhs for new inventory and another 25 lakhs for research and development."
                      className="form-control"
                      name="fundingSought"
                      value={formData.fundingSought}
                      onChange={handleChange}
                    />
                    {errors.fundingSought && (
                      <p className="error-msg">{errors.fundingSought}</p>
                    )}
                  </div>
                </form>
                <div className="btn-box flex space-between">
                  <Link to="/additional-questions" className="cta-btn back-btn">
                    Back
                  </Link>
                  <Link onClick={handleSubmit} className="cta-btn blue-btn">
                    {loading ? (
                      <div style={{ marginLeft: "-30%" }}>
                      <Loaderbutton/>{" "}
                      </div>
                    ) : (
                      "Next"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default SignupStep6;
