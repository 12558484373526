import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer3 from "../../common/Footer3";
import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function WelcomeInvestor() {
  const navigate = useNavigate();

  useEffect(() => {
    const userType = JSON.parse(localStorage.getItem("userType"));
    if (userType != null) {
      if (userType != "Investor") {
        navigate("/");
      } else {
        window.scroll(0, 0);
      }
    }
  }, []);
  return (
    <>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Welcome investor</title>
      
      </Helmet>
      <section className="welcome-sec">
        <div className="container">
          <div className="top-comman-text">
            <div className="logo">
              <a href="/">
                <img src="src/images/site-logo.svg" alt="sitelogo" />
              </a>
              <h4>Welcome to Your Investor Dashboard</h4>
              <p>
                Congratulations on completing your investor profile. We will
                review it and make a decision within 2 business days. Please
                monitor your email, including your spam folder. Once approved,
                you can log in to your dashboard as a Level 1 investor.
              </p>

              <div className="btn-box flex align-center space-center">
                <Link
                  to="/investor-dashboard"
                  className="cta-btn blue-btn Startup-btn startup-custom"
                >
                  Start browsing listings or explore educational resources to
                  enhance your investment journey.
                  <img src="src/images/arrow.svg" alt="icon" />
                </Link>
              </div>
              <figure>
                <img src="src/images/welcome-bg.svg" alt="welcome-bg" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default WelcomeInvestor;
