import React, { useEffect, useState } from "react";
import Header2 from "../../common/Header2";
import Header from "../../common/Header";
import { getItem } from "../../common/reusable";
import Footer2 from "../../common/Footer2";
import axios from "axios";
import { apiurl } from "../Url";
import Loader from "../../common/Loader";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
function Support() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [supportData, SetsupportData] = useState([]);
  const [initalsupportData, SetinitialsupportData] = useState([]);
  const [issearch,SetIssearch]=useState(false);
  const [loading, Setloading] = useState(true);
  const [getContact, setGetContact] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    axios
      .get(`${apiurl}visitor/getSupport`)
      .then((response) => {
        SetinitialsupportData(response?.data?.data)
        SetsupportData(response?.data?.data);
        Setloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handlequestionclick = (id) => {
    let questionsanswer = document?.getElementById(id);

    if (questionsanswer ) {
      questionsanswer.scrollIntoView({ behavior: "smooth" });
    } else {
    }
  };
  const [quicksearch, setquicksearch] = useState('');


  const quicktagSearch = (e) => {
    e.preventDefault();
    const lowerCasequicksearch = quicksearch.toLowerCase();
    const results = supportData.filter(item =>
      item.question.toLowerCase().includes(lowerCasequicksearch)
    );
    SetIssearch(true);
    SetsupportData(results);
  };
useEffect(()=>{
if(quicksearch===""&&issearch){
  SetsupportData(initalsupportData);
  SetIssearch(false);
}
},[quicksearch,issearch])

useEffect(() => {
  window.scrollTo(0, 0);
  axios
    .get(`${apiurl}visitor/getContact`)
    .then((response) => {
      setGetContact(response?.data?.data);
    })
    .catch((err) => {
      console.log(err);
    });
}, []);

  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      {getItem("token") != "" ? <Header2 /> : <Header />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Support</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="dashboard-sec support-page">
        <div className="container">
          <div className="bredcrum-box flex align-center">
            <div className="left-box">
              <h5>Support</h5>
              <ul className="flex">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Support</li>
              </ul>
            </div>
          </div>

          <div className="shadow-box custom-support">
            <h2>How can we help?</h2>
            <div className="filter-search-box flex space-between align-center">
              <div className="search-box">
                <img src="src/images/Search.svg" alt="" />
                <input
                  type="search"
                  id="gsearch"
                  name="gsearch"
                  placeholder="Search..."
                  value={quicksearch}
                  onChange={(e) => setquicksearch(e.target.value)}
                />
              </div>
              <a  className="cta-btn" onClick={(e)=>quicktagSearch(e)}>
                Search
              </a>
            </div>
            <ul className="quik-links flex">
              <li>
                <p>
                  <b>Quick Search Tag:</b>
                </p>
              </li>
              {supportData.length !== 0 && supportData !== "" ? (
                <>
                  {supportData.map((item, index) => (
                    <li key={index}>
                      <a onClick={(e)=>handlequestionclick(`${index}question`)}>{item.question}</a>
                    </li>
                  ))}
                </>
              ) : null}
            
            </ul>
            {supportData.length !== 0 && supportData !== "" ? (
              <>
                {supportData.map((item, index) => (
                  <div className="content-box" key={index}>
                    <h5 id={`${index}question`}>
                      <img src="./src/images/question.svg" alt="" />{" "}
                      {item.question}
                    </h5>
                    <ol>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: item.answer,
                        }}
                      ></li>
                    </ol>
                  </div>
                ))}
              </>
            ) : null}

            <div className="contact-us-bottom-sec">
              <h3>Contact Us</h3>
              <p>
                If you have any questions or concerns about these terms, please
                contact us at:
              </p>
              <ul className="flex">
                <li className="flex align-center">
                  <img src="./src/images/mail-icon.svg" alt="" />{" "}
                  <a href={getContact.email1}>{getContact.email1}</a>
                </li>
                <li className="flex align-center">
                  <img src="./src/images/call-icon.svg" alt="" />{" "}
                  <a href={getContact.email2}>{getContact.email2}</a>
                </li>
                <li className="flex align-center">
                  <img src="./src/images/location-blue.svg" alt="" />{" "}
                  <a > {getContact.address}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default Support;
