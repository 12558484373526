/* eslint-disable eqeqeq */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Loader from "../../common/Loader";
import { apiurl } from "../Url";
import { getItem } from "../../common/reusable";

function Section() {
  const [getpop, setGetpop] = useState(false);
  const [homedata, SetHomedata] = useState([]);
  const [testimonialdata, SetTestimonialdata] = useState([]);
  const settings1 = {
    centerMode: true,
    slidesToShow: 3,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const navigate = useNavigate();
  const [loading, Setloading] = useState(true);
  const [stop, setStop] = useState(false);
  const [subrcribehome, setsubrcribehome] = useState("");
  const [openvideopopup, setopenvideopopup] = useState(true);

  const [errors, setErrors] = useState("");
  const handleChange = e => {
    const { value } = e.target;
    setsubrcribehome(value);
    setErrors(value);
  };

  const subscibedhome = e => {
    e.preventDefault();

    const newErrors = {};
    if (!subrcribehome) {
      newErrors.subrcribehome = "Email is required.";
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(subrcribehome)
    ) {
      newErrors.subrcribehome = "Please enter a valid email address.";
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        axios
          .post(`${apiurl}visitor/getSubscribe`, {
            email: subrcribehome,
          })
          .then(response => {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setsubrcribehome("");
            } else {
              toast.success("Email subscribed successfully.");
              setsubrcribehome("");
              setGetpop(false);
            }
          })
          .catch(error => {
            console.error(error);
          });
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    }
  };

  useEffect(() => {
    axios
      .get(`${apiurl}visitor/home`)
      .then(response => {
        SetHomedata(response?.data.data[0]);
        Setloading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (stop === false) {
      axios
        .get(`${apiurl}visitor/getTestimonial`)
        .then(response => {
          SetTestimonialdata([...response?.data?.data]);
          setStop(true);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
    }
  }, [stop]);

  const openVideo = () => {
    setopenvideopopup(false);
  };
  const openGetstarted = () => {
    setGetpop(true);
  };
  const openGetstartedlogin = () => {
    if (getItem("userType") != "") {
      if (getItem("userType") != "StartUp") {
        navigate("/investor-dashboard");
      } else {
        navigate("/messenger");
      }
    } else {
      navigate("/login");
    }
  };
  const closeGetstarted = () => {
    setGetpop(false);
    setsubrcribehome("");
  };
  const stopVideo = () => {
    setopenvideopopup(true);
    document.body.classList.remove("pop-active");
    // $('body').removeClass('pop-active');
  };
  const gotoSignpage = e => {
    e.preventDefault();
    if (getItem("userType") != "") {
      if (getItem("userType") != "StartUp") {
        navigate("/investor-dashboard");
      } else {
        navigate("/messenger");
      }
    } else {
      navigate("/signup");
    }
  };

  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      {getpop && (
        <div className="popup-container-custom">
          {" "}
          <div className="popup-wrapper pop-open" id="startup-popup">
            <div className="pop-cross-btn">
              <img
                src="src/images/cross-icon.svg"
                alt=""
                onClick={closeGetstarted}
              />
            </div>
            <div className="inner-poupup">
              <h1>Subscribe For Our Newsletter</h1>
              <form action="">
                <div className="form-wrapper-box flex">
                  <div className="form-group full-width">
                    <input
                      type="email"
                      placeholder="Example@gmail.com"
                      className="form-control"
                      value={subrcribehome}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group btngroup full-width flex">
                    <button
                      className="cta-btn border"
                      onClick={closeGetstarted}
                    >
                      Cancel
                    </button>
                    <button className="cta-btn" onClick={subscibedhome}>
                      Subscribe
                    </button>
                    {errors.subrcribehome && (
                      <p className="subscribe-error homesubscribeeror">
                        {errors.subrcribehome}
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {!openvideopopup && (
        <div
          className="popup-wrapper pop-open custom-popup-hight"
          id="demo-popup"
          // onClick={stopVideo}
        >
          <div className="pop-cross-btn">
            <img src="src/images/cross-icon.svg" alt="" onClick={stopVideo} />
          </div>
          <div className="inner-poupup">
            <div className="video-container">
              <iframe
                title="popupVideo"
                id="popupVideo"
                width="560"
                height="315"
                src={openvideopopup ? "" : `${homedata?.video}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}

      <section className="home-banner-sec">
        <div className="container">
          <div className="row flex">
            <div className="col-50">
              <div className="text">
                <div className="sub-title">{homedata?.bestHeading}</div>
                <h1>
                  {/* <span className="line">Empowering Startups,</span> Connecting
                  Dreams. */}
                  {/* {homedata?.bestTitle} */}
                  <span className="line">
                    {homedata?.bestTitle?.split(" ").slice(0, 2).join(" ")}
                  </span>
                  {homedata?.bestTitle &&
                    homedata?.bestTitle.split(" ")?.slice(2).join(" ")}
                </h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: homedata?.bestDescription,
                  }}
                ></p>
                <div className="btn-wrap-box hide">
                  <a
                    // href="javascript:void(0);"
                    className="cta-btn btn-orange"
                    id="started-pop-btn"
                    onClick={openGetstartedlogin}
                  >
                    Get Started
                  </a>
                  <a
                    // href="javascript:void(0);"
                    className="cta-btn border"
                    id="demo-pop-btn"
                    onClick={openVideo}
                  >
                    Our Process
                  </a>
                </div>
                <div className="animation_box">
                  <div className="trangle">
                    <img src="src/images/trangle.svg" alt="icon" />
                  </div>
                  <div className="icon small-cricle"></div>
                  <div className="icon squre-box"></div>
                  <div className="icon fill-cricle"></div>
                  <div className="icon cricle"></div>
                  <div className="trangle rotate">
                    <img src="src/images/trangle.svg" alt="icon" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-50">
              {homedata?.bestImage &&   <figure>
                <img
                  src={`${homedata?.bestImage}`}
                 
                  className="full-img"
                />
              </figure>}
            
              <div className="mobilehomebutton">
                {" "}
                <div className="btn-wrap-box">
                  <a
                    // href="javascript:void(0);"
                    className="cta-btn btn-orange"
                    id="started-pop-btn"
                    onClick={openGetstartedlogin}
                  >
                    Get Started
                  </a>
                  <a
                    // href="javascript:void(0);"
                    className="cta-btn border"
                    id="demo-pop-btn"
                    onClick={openVideo}
                  >
                    Our Process
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="logo-wrapper hiddenstorysec">
            <ul>
              <li>
                <img src={`${homedata?.logo1}`} alt="" />
              </li>
              <li>
                <img src={`${homedata?.logo2}`} alt="" />
              </li>
              <li>
                <img src={`${homedata?.logo3}`} alt="" />
              </li>
              <li>
                <img src={`${homedata?.logo4}`} alt="" />
              </li>
              <li>
                <img src={`${homedata?.logo5}`} alt="" />
              </li>
              <li>
                <img src={`${homedata?.logo6}`} alt="" />
              </li>
            </ul>
            <div className="logo-slider-img">
              <div className="slider-wrapper-box">
                <div>
                  <div className="img-box">
                    <img src={`${homedata?.logo1}`} alt="" />
                  </div>
                </div>
                <div>
                  <div className="img-box">
                    <img src={`${homedata?.logo2}`} alt="" />
                  </div>
                </div>
                <div>
                  {" "}
                  <div className="img-box">
                    <img src={`${homedata?.logo3}`} alt="" />
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className="img-box">
                    <img src={`${homedata?.logo4}`} alt="" />
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className="img-box">
                    <img src={`${homedata?.logo5}`} alt="" />
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className="img-box">
                    <img src={`${homedata?.logo6}`} alt="" />
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="logo-wrapper">
            <ul>
              <li>
                <img src={`${homedata?.logo1}`} alt="" />
              </li>
              <li>
                <img src={`${homedata?.logo2}`} alt="" />
              </li>
              <li>
                <img src={`${homedata?.logo3}`} alt="" />
              </li>
              <li>
                <img src={`${homedata?.logo4}`} alt="" />
              </li>
              <li>
                <img src={`${homedata?.logo5}`} alt="" />
              </li>
              <li>
                <img src={`${homedata?.logo6}`} alt="" />
              </li>
            </ul>
          </div> */}
        </div>
      </section>

      <section className="how-work-sec">
        <div className="container-lg">
          <div className="top-comman-text text-center">
            <div className="sub-title">{homedata?.howWorkHeading}</div>
            <h2>
              <span className="line">{homedata?.howWorkTitle}</span>
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: homedata?.howWorkDescription,
              }}
            ></p>
          </div>
          <div className="step-row flex space-between align-center">
            <div className="text-col">
              <div className="sub-title">Step 1</div>
              <h3>
                {homedata.step1Title != undefined ? (
                  <>
                    {homedata.step1Title.split(" ").slice(0, 1).join(" ")}{" "}
                    <span>
                      {homedata.step1Title.split(" ").slice(1, 2).join(" ")}
                    </span>{" "}
                    {homedata.step1Title.split(" ").slice(2).join(" ")}
                  </>
                ) : (
                  ""
                )}
              </h3>

              <p
                dangerouslySetInnerHTML={{
                  __html: homedata?.step1Description,
                }}
              ></p>
            </div>
            <div className="img-col">
              <img src={`${homedata?.step1Image}`} alt="icon" />
            </div>
          </div>
          <div className="step-row flex space-between align-center flex-reverse">
            <div className="text-col">
              <div className="sub-title">Step 2</div>
              <h3>
                {/* Connect <span>with</span> Investors */}
                {homedata.step2Title != undefined ? (
                  <>
                    {homedata.step2Title.split(" ").slice(0, 1).join(" ")}{" "}
                    <span>
                      {homedata.step2Title.split(" ").slice(1, 2).join(" ")}
                    </span>{" "}
                    {homedata.step2Title.split(" ").slice(2).join(" ")}
                  </>
                ) : (
                  ""
                )}
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: homedata?.step2Description,
                }}
              ></p>
            </div>
            <div className="img-col">
              <img src={`${homedata?.step2Image}`} alt="icon" />
            </div>
          </div>
          <div className="step-row flex space-between align-center">
            <div className="text-col">
              <div className="sub-title">Step 3</div>
              <h3>
                {/* Share <span>Your</span> Business Plan */}
                {homedata.step3Title != undefined ? (
                  <>
                    {homedata.step3Title.split(" ").slice(0, 1).join(" ")}{" "}
                    <span>
                      {homedata.step3Title.split(" ").slice(1, 2).join(" ")}
                    </span>{" "}
                    {homedata.step3Title.split(" ").slice(2).join(" ")}
                  </>
                ) : (
                  ""
                )}
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: homedata?.step3Description,
                }}
              ></p>
            </div>
            <div className="img-col">
              <img src={`${homedata?.step3Image}`} alt="icon" />
            </div>
          </div>
          <div className="step-row flex space-between align-center flex-reverse">
            <div className="text-col">
              <div className="sub-title">Step 4</div>
              <h3>
                {/* Engage<span> and</span> Negotiate */}
                {homedata.step4Title != undefined ? (
                  <>
                    {homedata.step4Title.split(" ").slice(0, 1).join(" ")}{" "}
                    <span>
                      {homedata.step4Title.split(" ").slice(1, 2).join(" ")}
                    </span>{" "}
                    {homedata.step4Title.split(" ").slice(2).join(" ")}
                  </>
                ) : (
                  ""
                )}
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: homedata?.step4Description,
                }}
              ></p>
            </div>
            <div className="img-col">
              <img src={`${homedata?.step4Image}`} alt="icon" />
            </div>
          </div>
          <div className="step-row flex space-between align-center">
            <div className="text-col">
              <div className="sub-title">Step 5</div>
              <h3>
                {/* {" "}
                Secure Funding <span>and</span> Transfer Equity */}
                {homedata.step4Title != undefined ? (
                  <>
                    {homedata.step5Title.split(" ").slice(0, 2).join(" ")}{" "}
                    <span>
                      {homedata.step5Title.split(" ").slice(2, 3).join(" ")}
                    </span>{" "}
                    {homedata.step5Title.split(" ").slice(3).join(" ")}
                  </>
                ) : (
                  ""
                )}
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: homedata?.step5Description,
                }}
              ></p>
            </div>
            <div className="img-col">
              <img src={`${homedata?.step5Image}`} alt="icon" />
            </div>
          </div>
          <div className="btn-box flex align-center space-center">
            <a className="cta-btn blue-btn" onClick={gotoSignpage}>
              Get Started and Connect with Investors Today{" "}
              <img src="src/images/arrow.svg" alt="icon" />
            </a>
          </div>
        </div>
      </section>

      {/* <!-- Opportunity-sec --> */}
      <section className="opportunity-sec">
        <div className="container">
          <div className="top-comman-text text-center">
            <div className="sub-title">{homedata?.featureHeading}</div>
            <h2>
              <span className="line">{homedata?.featureTitle}</span>
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: homedata?.fundingDetail,
              }}
            ></p>
          </div>
          <div className="content-row homepagecontent">
            <div className="box">
              <figure>
                <img src={`${homedata?.secureImage}`} alt="opportunity img" />
              </figure>
              <h5>{homedata?.secureTitle}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: homedata?.secureDetail,
                }}
              ></p>
            </div>
            <div className="box">
              <figure>
                <img
                  src={`${homedata?.structureImage}`}
                  alt="opportunity img"
                />
              </figure>
              <h5>{homedata?.structureTitle}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: homedata?.structureDetail,
                }}
              ></p>
            </div>
            <div className="box">
              <figure>
                <img src={`${homedata?.investorImage}`} alt="opportunity img" />
              </figure>
              <h5>{homedata?.investorTitle}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: homedata?.investorDetail,
                }}
              ></p>
            </div>
            <div className="box">
              <figure>
                <img src={`${homedata?.fundingImage}`} alt="opportunity img" />
              </figure>
              <h5>{homedata?.fundingTitle}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: homedata?.fundingDetail,
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>

      <section className="news-letter-sec">
        <div className="container">
          <div className="map-img">
            <figure>
              <img src={`${homedata?.subscriptionImage}`} alt="map-img" />
            </figure>
            {/* <div className="img-tooltip-box">
              <figure>
                <img src="src/images/tooltipimg01.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg02.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg03.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg04.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg05.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg06.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg07.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg08.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg09.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg10.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg11.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg12.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
              <figure>
                <img src="src/images/tooltipimg13.png" alt="tooltip" />
                <div className="tool-tip-text">
                  <p>Explore Features</p>
                  <p>
                    Discover More About Our Platform's Features and Benefits!
                  </p>
                </div>
              </figure>
            </div> */}
          </div>
          <div className="top-comman-text text-center">
            <h2>
              <span className="line">
                {/* Sign Up <span>for Our Newsletter</span> */}
                {homedata?.subscriptiontitle}
              </span>
            </h2>
            <a
              className="cta-btn btn-blue getstarted-btn "
              onClick={openGetstarted}
            >
              Get Started <img src="src/images/arrow.svg" alt="icon" />
            </a>
          </div>
        </div>
      </section>

      <section className="success-stroie-sec hiddenstorysec">
        <div className="container">
          <div className="top-comman-text text-center">
            <div className="sub-title">What Our Users Are Saying</div>
            <h2>
              <span className="line">Success Stories</span>
            </h2>
            <p>
              Discover how startups and investors have found success on our
              platform.
            </p>
          </div>
        </div>
        <div className="bg-img">
          <img src="src/images/story-bg-logo.svg" alt="" />
        </div>
        <Slider {...settings1} className="success-stroies-slider">
          {testimonialdata.map((item, index) => (
            <div key={index}>
              <div className="box">
                <ul>
                  <li>
                    <h4>{item.name}</h4>
                    <h3>{item?.designation}</h3>
                  </li>
                  <li>
                    <img src={item?.photo} alt="" />
                  </li>
                </ul>
                <p dangerouslySetInnerHTML={{ __html: item?.detail }}></p>
              </div>
            </div>
          ))}
        </Slider>

        {/* <div className="success-stroies-slider">
          {testimonialdata
            ? testimonialdata?.map((item, index) => (
                <div key={index}>
                  <div className="box">
                    <ul>
                      <li>
                        <h4>{item.name}</h4>
                        <h3>{item?.designation}</h3>
                      </li>
                      <li>
                        <img src={item?.photo} alt="" />
                      </li>
                    </ul>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item?.detail,
                      }}
                    ></p>
                  </div>
                </div>
              ))
            : ""}


        </div> */}
        <div className="btn-box flex align-center space-center">
          <a className="cta-btn btn-orange" onClick={gotoSignpage}>
            Join Our Platform and Start Your Success Story{" "}
            <img src="src/images/arrow.svg" alt="icon" />
          </a>
        </div>
      </section>
    </>
  );
}
export default Section;
