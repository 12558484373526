/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLinkedIn } from "react-linkedin-login-oauth2";

import Footer3 from "../../common/Footer3";
import { getItem, Loading, removeItem, setItem } from "../../common/reusable";
import { ChatContext } from "../../Context/ChatContext";
import { forgetpassword, home, login, terms } from "../../Routes/Routes";
import { apiurl } from "../Url";
import Loader from "../../common/Loader";

function Login() {
  const navigate = useNavigate();
  const [newcreds, setNewcreds] = useState(false);
  const [call, setCall] = useState(false);
  const [calltoken, setCalltoken] = useState("");
  const [loadingloader, Setloadingloader] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    acceptPolicy:
      getItem("acceptLoginPolicy") != "" ? getItem("acceptLoginPolicy") : false,
  });
  const [date, setDate] = useState("");
  useEffect(() => {
    const formatDate = date => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };
    const todayDate = new Date();
    const formattedTodayDate = formatDate(todayDate);
    const loginDateDateStr = getItem("loginDate");
    if (loginDateDateStr) {
      const [loginDateDay, loginDateMonth, loginDateYear] = loginDateDateStr
        .split("-")
        .map(Number);
      const loginDateDate = new Date(
        loginDateYear,
        loginDateMonth - 1,
        loginDateDay
      );
      const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;
      const timeDifference = todayDate - loginDateDate;
      if (timeDifference > oneWeekInMillis) {
        setDate(formattedTodayDate);
        removeItem("loginDate");
        removeItem("email");
        removeItem("password");
        removeItem("acceptLoginPolicy");
      } else {
        setDate(loginDateDateStr);
        console.log(
          getItem("acceptLoginPolicy"),
          getItem("acceptLoginPolicy") == true
        );
        if (getItem("acceptLoginPolicy") == true) {
          setFormData(prevstate => ({
            ...prevstate,
            email: getItem("email"),
            password: getItem("password"),
          }));
        }
      }
    } else {
      setDate(formattedTodayDate);
    }
  }, []);
  useEffect(() => {
    if (newcreds) {
      const formatDate = date => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };
      const todayDate = new Date();
      const formattedTodayDate = formatDate(todayDate);
      setDate(formattedTodayDate);
    }
  }, [newcreds]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const isValidEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const { setStartConnection } = useContext(ChatContext);

  const handleChange = e => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setNewcreds(true);
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const GoogleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        // Fetch user information from Google
        const userInfoResponse = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`
        );
        const userInfo = userInfoResponse.data;
        Setloadingloader(true);
        // Send the token to your backend
        const response = await axios.post(`${apiurl}users/googlelogin`, {
          google: userInfo,
        });
        const result = response.data;

        if (result?.status == true) {
          setItem("acceptLoginPolicy", formData?.acceptPolicy);
          setItem("token", result?.token);
          setItem("userType", result?.user?.userType);
          setItem("UserId", result?.user?._id);

          setStartConnection(true);
          setItem("url", result?.user?.nextUrl);
          window.location.href = result?.user?.nextUrl;

          // navigate(result?.user?.nextUrl);
          // if (result?.user?.userType == "Investor") {
          //   navigate(investordashboard);
          // } else {
          //   navigate(startupdashboard);
          // }
        }
        if (result.message == "Invalid email") {
          toast.error("This email is not yet registered.");
          Setloadingloader(false);
        }
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
    },
    onFailure: error => {
      console.error(error);
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.password) {
      newErrors.password = "Password is required.";
    }
    // if (!formData.acceptPolicy) {
    //   newErrors.acceptPolicy = "Please Accept Terms and Conditions.";
    // }
    setErrors(newErrors);
    // if (Object.keys(newErrors).length === 0) {
    //   navigate("/");
    // }
    if (
      isValidEmail(formData.email) &&
      formData?.password != ""
      // formData?.acceptPolicy == true
    ) {
      setLoading(true);
      try {
        axios
          .post(`${apiurl}users/login`, {
            email: formData.email,
            password: formData.password,
          })
          .then(res => {
            if (res?.status == 200) {
              if (res?.data?.status == true) {
                setLoading(false);
                setItem("UserId", res?.data?.user?._id);
                setItem("acceptLoginPolicy", formData?.acceptPolicy);
                setItem("token", res?.data?.token);
                setItem("userType", res?.data?.user?.userType);
                setItem("email", formData.email);
                setItem("password", formData.password);
                setItem("loginDate", date);
                if (res?.data?.user?.nextUrl != null) {
                  setStartConnection(true);
                  setItem("url", res?.data?.user?.nextUrl);
                  window.location.href = res?.data?.user?.nextUrl;
                } else {
                  setStartConnection(true);
                  window.location.href = "/";
                }

                // if (res?.data?.user?.userType === "Investor") {
                //   navigate(investordashboard);
                // } else {
                //   navigate("/");
                // }
              } else {
                setLoading(false);
                toast.error(res?.data?.message);
              }
            } else {
              setLoading(false);
              toast.error(res?.data?.message);
            }
          })
          .catch(err => {
            setLoading(false);
            console.log(err);
          });
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };
  useEffect(() => {
    window.scroll(0, 0);
    let path = window.location.pathname;
    if (path == login || path == forgetpassword) {
      getItem("token") != "" && navigate(home);
    }
  }, []);
  const fetchProfileData = async accessToken => {
    Setloadingloader(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      linkedIn: accessToken,
      redirect_uri: `${window.location.origin}/linkedin`,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiurl}users/linkedInlogin`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result?.status == true) {
          setItem("acceptLoginPolicy", formData?.acceptPolicy);
          setItem("token", result?.token);
          setItem("userType", result?.user?.userType);
          setItem("UserId", result?.user?._id);
          setCall(false);
          setStartConnection(true);
          setItem("url", result?.user?.nextUrl);
          window.location.href = result?.user?.nextUrl;
          // navigate(result?.user?.nextUrl);
        } else {
          toast.error(result.message);
          Setloadingloader(false);
          setCall(false);
        }
      })
      .catch(error => console.error(error));
  };
  const { linkedInLogin } = useLinkedIn({
    clientId: "77yms1uz2h8tx2",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid profile email",
    onSuccess: response => {
      setCall(true);
      setCalltoken(response);
      // fetchProfileData(response);
    },
    onError: error => {
      console.log(error);
    },
  });
  useEffect(() => {
    if (call) {
      fetchProfileData(calltoken);
    }
  }, [call]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Login</title>
      </Helmet>
      <div
        className="loading-overlay"
        style={{ display: loadingloader ? "block" : "none" }}
      >
        <Loader />
      </div>
      <section className="create-account-sec login-up-sec">
        <div className="container">
          <div className="row flex">
            <div className="form-box w-100 m-auto">
              <div className="form-wrapper">
                <div className="top-comman-text text-center">
                  <Link to="/">
                    <img src="src/images/site-logo.svg" alt="" />
                  </Link>
                  <h4>Login</h4>
                  <p>
                    Have question{" "}
                    <a href="/" className="hover-line">
                      Getting Started
                    </a>{" "}
                    guide
                  </p>
                </div>
                <ul className="signup-method flex">
                  <li onClick={GoogleLogin}>
                    <img src="src/images/Google.svg" alt="" />
                    Login with Google
                  </li>
                  <li onClick={linkedInLogin}>
                    <img src="src/Linkedin1.png" alt="" />
                    Login with LinkedIn
                  </li>
                  <a className="with-mail">
                    <span>Or with email</span>
                  </a>
                </ul>
                <form action="">
                  <div className="form-group loginpassword">
                    <label htmlFor="">Useremail</label>
                    <input
                      type="text"
                      placeholder="John.doe@udharaa.com"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="error-msg">{errors.email}</span>
                    )}
                  </div>
                  <div className="form-group loginpassword">
                    <label htmlFor="">Password</label>
                    <input
                      type="password"
                      placeholder="************"
                      className="form-control"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <span className="error-msg">{errors.password}</span>
                    )}
                    <p className="forget-pass">
                      <Link to="/forget-password" className="hover-line">
                        Forget Password
                      </Link>
                    </p>
                  </div>
                  <div className="form-group checkbox">
                    <label className="flex">
                      Trust this device for week{" "}
                      <Link to={terms}>Terms & Conditions</Link>
                      <input
                        type="checkbox"
                        checked={formData?.acceptPolicy}
                        onChange={handleChange}
                        name="acceptPolicy"
                      />
                      {errors?.acceptPolicy && (
                        <p className="error-msg">{errors.acceptPolicy}</p>
                      )}
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      style={loading ? { backgroundColor: "#FFDE59" } : {}}
                      disabled={loading}
                      type="submit"
                      onClick={handleSubmit}
                      className="cta-btn"
                    >
                      {loading ? <Loading /> : "Login"}
                    </button>
                  </div>
                  <div className="form-group sign-in">
                    <p>
                      Don’t have an Account? <Link to="/signup">Sign up</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default Login;
