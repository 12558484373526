import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer3 from "../../common/Footer3";

function PasswordChangeScucess() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Password change success</title>
      
      </Helmet>
      <section className="create-account-sec password-change-scucess">
        <div className="container">
          <div className="row flex">
            <div className="form-box w-100 m-auto">
              <div className="form-wrapper">
                <div className="top-comman-text text-center">
                  <Link to="/">
                    <img src="src/images/site-logo.svg" alt="" />
                  </Link>
                  <h4>Password is changed</h4>
                  <p>
                    Your password is successfully changed. Please Sign in to
                    your account and start a new project
                  </p>
                </div>
                <form action="">
                  <div className="form-group">
                    <Link to="/login">
                      <button className="cta-btn">Login</button>
                    </Link>
                  </div>
                  {/* <div className="form-group sign-in">
                    <p>
                      Did’t receive an email?{" "}
                      <Link to="/forget-password">Try Again</Link>
                    </p>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default PasswordChangeScucess;
